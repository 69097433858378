import React from 'react'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  titleReset: string
  resetClick: () => void
  titleSubmit: string
  okClick: () => void
  disabled: boolean
  loading?: boolean
  classNameSubmit?: string
  classNameCancel?: string
}

const SappFilterButton = ({
  okClick,
  resetClick,
  titleReset,
  titleSubmit,
  disabled,
  loading,
  classNameSubmit,
  classNameCancel,
}: IProps) => {
  return (
    <div className='d-flex'>
      <ButtonSecondary
        title={titleReset}
        className={`${classNameCancel ?? ''} me-4`}
        onClick={resetClick}
        disabled={disabled}
        size='small'
        loading={false}
      />

      <div className='w-100'>
        <ButtonPrimary
          title={titleSubmit}
          onClick={okClick}
          disabled={disabled}
          loading={loading}
          size='small'
        />
      </div>
    </div>
  )
}

export default SappFilterButton
