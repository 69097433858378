import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { ConfigProvider, Space, Table } from 'antd'
import { TableProps } from 'antd/es/table'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { KTIcon } from 'src/_metronic/helpers'
import { DepartmentAPI } from 'src/apis/department'
import ActionCell from 'src/components/base/action/ActionCell'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { PageLink, QueryKeys } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { IDepartmentTree, IDepartmentTreeTable } from 'src/type'
import DepartmentCreate from '../DepartmentCreate'
import styles from './styles.module.scss'
import './styles.scss'
import { Link, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { BUSINESS_UNITS } from 'src/type/department/enum'

const getBadgeColor = (unit: string) => {
  switch (unit.toLowerCase()) {
    case 'ho':
      return 'badge-primary'
    case 'acca':
      return 'badge-acca'
    case 'cfa':
      return 'badge-cfa'
    case 'cma':
      return 'badge-cma'

    default:
      break
  }
}

const getAllKeys = (data: IDepartmentTree[]): string[] => {
  let keys: string[] = []

  // Traverse through each row in the data array
  data?.forEach((item) => {
    // Add the key of the current item to the array
    keys.push(item.id!)

    // If the item has child, recursively call getAllKeys to get keys from the child
    if (item.child) {
      keys = [...keys, ...getAllKeys(item.child)]
    }
  })

  return keys
}

const processTableData = (data: IDepartmentTree[], level = 0, parentLastItem = false) => {
  return data.map((item, index) => {
    const isLastItem = index === data.length - 1 // Check if it's the last child
    const isOnlyChild = data.length === 1
    const insideLastLevel = parentLastItem

    const updatedItem = { ...item, level, lastItem: isLastItem, insideLastLevel, isOnlyChild }

    if (item.child && item.child.length > 0) {
      // Recursive to process nested item
      updatedItem.child = processTableData(item.child, level + 1, isLastItem)
    }

    return updatedItem
  })
}

const fetchDepartments = async (filters: FieldValues) => {
  const response = await DepartmentAPI.getTree({
    page_index: 1,
    page_size: 10,
    params: {
      ...filters,
    },
  })
  return response.data.data
}

type IFilters = {
  name?: string
}
const createUrl = '/departments/create'
const DepartmentList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [expandedRowKeys, setExpandedRowsKeys] = useState<string[]>([])
  const [isReset, setIsReset] = useState<boolean>(false)
  const { control, watch, handleSubmit, reset } = useForm<IFilters>({
    defaultValues: {
      name: undefined,
    },
  })

  const onSubmit: SubmitHandler<IFilters> = () => {
    refetch()

    setIsReset(false)
  }

  const filters = watch()
  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.DEPARTMENTS, isReset],
    queryFn: () => fetchDepartments(filters),
    select: (data) => {
      const processedData = processTableData(data)
      return processedData
    },
    onSuccess: (data) => {
      const allKeys = getAllKeys(data)
      setExpandedRowsKeys(allKeys)
    },
    refetchOnWindowFocus: false,
  })

  // const [expandedRowKeys, setExpandedRowsKeys] = useState<string[]>(allKeys)
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const queryClient = useQueryClient()

  const { mutate: deleteExam } = useMutation({
    mutationFn: (examIds: string[]) => DepartmentAPI.delete(examIds),
    onSuccess: (res) => {
      if (res.success) {
        toast.success(res.data.message)
      }

      queryClient.invalidateQueries(QueryKeys.DEPARTMENTS)
    },
  })

  const handleDelete = (id: string[]) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xoá không?'],
      onClick: () => deleteExam(id),
    })
  }

  const columns: TableProps<IDepartmentTreeTable>['columns'] = [
    {
      title: 'Department Name',
      dataIndex: 'name',
      render: (value, record) => {
        return (
          <div className={styles.firstCol}>
            {[...Array(record?.level || 0)].map((_, i) => (
              <div
                key={i}
                className={clsx(styles.indentBlock, {
                  [styles.indentBlockDrawing]: (record?.level as number) >= 2,
                })}
              />
            ))}
            {record.level === 0 && <div className={clsx(styles.firstIndentBlock)} />}
            <Link
              className={clsx('text-gray-800 text-hover-primary', styles.firstCol)}
              to={`${PageLink.DEPARTMENTS}/${record.id}`}
            >
              {value}
            </Link>
          </div>
        )
      },
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: 200,
      render: (value) => {
        return (
          <>
            <span className={clsx('badge fs-7 my-1 me-1 badge-primary')}>{BUSINESS_UNITS.HO}</span>
            {value !== BUSINESS_UNITS.HO && (
              <span className={clsx('badge fs-7 my-1 me-1', getBadgeColor(value))}>{value}</span>
            )}
          </>
        )
      },
    },
    {
      title: 'Number of employees',
      dataIndex: 'staff_count',
      width: 200,
      render: (value) => <p className={styles.noOfEmployeeCol}>{value}</p>,
    },
    {
      title: '',
      fixed: 'right',
      width: 10,
      render: (record) => (
        <div className={styles.actionCell}>
          <ActionCell height={21}>
            <div
              className='menu-item px-3'
              onClick={() => navigate(`${PageLink.DEPARTMENTS}/${record.id}/setting`)}
            >
              <div className='menu-link px-3'>Edit</div>
            </div>
            <div
              className='menu-item px-3'
              onClick={() => {
                return handleDelete([record.id])
              }}
            >
              <div className='menu-link px-3'>Delete</div>
            </div>
          </ActionCell>
        </div>
      ),
    },
  ]

  const handleReset = () => {
    reset({ name: undefined })

    setIsReset(true)
  }

  useEffect(() => {
    if (location.pathname === createUrl) {
      setOpenCreateDrawer(true)
    }
  }, [location])

  const generateTreeData = (data: any, parentDepth = 0) => {
    return data?.map((item: any) => ({
      ...item,
      depth: parentDepth, // Add depth level
      children: item.children ? generateTreeData(item.children, parentDepth + 1) : undefined,
    }))
  }

  const treeData = generateTreeData(data)

  return (
    <Space
      direction='vertical'
      size='middle'
      style={{
        display: 'flex',
      }}
    >
      {contextHolder}
      <form className='card p-9' onSubmit={handleSubmit(onSubmit)}>
        <ListFilterLayout>
          {/* Tháng thi */}
          <HookFormTextField
            control={control}
            name='name'
            placeholder='Search Department'
            defaultValue={''}
          />
        </ListFilterLayout>
        <div
          className={clsx(['d-flex justify-content-between align-items-stretch p-0 border-0 mt-5'])}
        >
          <div className='d-flex'>
            <ButtonSecondary
              title={'Reset'}
              className={`me-4`}
              onClick={handleReset}
              loading={false}
              disabled={isLoading || isFetching}
            />
            <ButtonPrimary
              title={'Search'}
              loading={false}
              disabled={isLoading || isFetching}
              onClick={() => {
                handleSubmit(onSubmit)
              }}
              type='submit'
            />
          </div>
          <div className={clsx('d-flex justify-content-between')}>
            {/* Don't show upload button when there're selected items */}

            <ButtonIcon
              title={'Create Department'}
              isListScreen
              onClick={() => navigate(createUrl)}
              type='button'
            >
              <KTIcon iconName='plus' className='fs-4' />
            </ButtonIcon>
          </div>
        </div>
      </form>
      <div className='card p-9 pt-4'>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#ffffff',
                headerSplitColor: '#ffffff',
                headerColor: '#99A1B7',
                borderColor: '#ffffff',
                cellPaddingBlock: 16,
              },
            },
          }}
        >
          {data && (
            <Table<IDepartmentTreeTable>
              tableLayout='auto'
              columns={columns}
              dataSource={treeData}
              pagination={false}
              loading={isLoading}
              rowKey={(record) => record.id!}
              expandable={{
                childrenColumnName: 'child',
                defaultExpandAllRows: true,
                expandedRowKeys: expandedRowKeys,
                fixed: 'right',
                onExpand: (expanded, record) => {
                  expanded
                    ? setExpandedRowsKeys([...expandedRowKeys, record.id as string])
                    : setExpandedRowsKeys(expandedRowKeys.filter((id) => id !== record.id))
                },
                expandIcon: ({ expanded, onExpand, record }) => {
                  if (record.child && record.child.length > 0) {
                    const leftStyle =
                      record?.level === 0 ? '20px' : `${(record?.level ?? 0) * 39.5}px`

                    return expanded ? (
                      <DownOutlined
                        className={styles.expandableIcon}
                        onClick={(e) => onExpand(record, e)}
                        style={{
                          left: leftStyle,
                        }}
                      />
                    ) : (
                      <RightOutlined
                        className={styles.expandableIcon}
                        onClick={(e) => onExpand(record, e)}
                        style={{
                          left: leftStyle,
                        }}
                      />
                    )
                  }
                  return null // Ensure you return `null` for cases where the record does not have children.
                },
              }}
              scroll={{ x: '1000px' }}
              rowClassName={(record, index) => {
                const lastItemStyle = record.lastItem && `last-level-${record.level}`
                const insideLastItemStyle =
                  record.level && record.insideLastLevel && `inside-last-level-${record.level - 1}`
                return clsx(
                  lastItemStyle,
                  insideLastItemStyle,
                  index === 0 ? styles.firstRow : styles.row
                )
              }}
              className={styles.departmentTable}
            />
          )}
        </ConfigProvider>
      </div>
      <DepartmentCreate open={openCreateDrawer} setOpen={setOpenCreateDrawer} />
    </Space>
  )
}

export default DepartmentList
