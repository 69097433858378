import { getAPIUserExport, getPermissionReport } from 'src/apis/user'
import { IStudentDetail } from 'src/type/students'
import * as XLSX from 'xlsx'
import { convertString, convertTime, fileExtension, fileType, formatDate } from 'src/utils'
import FileSaver from 'file-saver'
import { getAPIStaffsExport } from 'src/apis/staffs'
import { IReportPermission } from 'src/type/roles'
import { EventTestAPI } from 'src/apis/event-test'
import { format } from 'date-fns'

export class UserExportHelper {
  private static wscols: Array<{ wch: number }> = [
    { wch: 5 },
    { wch: 15 },
    { wch: 15 },
    { wch: 30 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
  ]

  private static header = [
    'stt',
    // 'key',
    'id',
    'full_name',
    'username',
    'email',
    'phone',
    // 'class',
    'type_user',
    'status',
  ]

  private static heading = [
    {
      stt: 'STT',
      // key: 'Code',
      id: 'ID',
      full_name: 'Full Name',
      username: 'User Name',
      email: 'Email',
      phone: 'Phone',
      // class: 'Class',
      type_user: 'Type User',
      status: 'Status',
    },
  ]

  private static headerTeacher = [
    'stt',
    'key',
    'full_name',
    'username',
    'email',
    'phone',
    'address',
    'dob',
    'note',
    'gender',
    'status',
    'facility',
    'current_company',
  ]

  private static headerReportPermission = [
    'stt',
    'module',
    'name',
    'route',
    'method',
    'display_name',
    'description',
    'relations',
    'permissions_parent',
  ]
  private static headerReportGroupPermission = ['module', 'name', 'display_name', 'description']
  private static headingTeacher = [
    {
      stt: 'STT',
      key: 'Code',
      full_name: 'Full Name',
      username: 'User Name',
      email: 'Email',
      phone: 'Phone',
      address: 'Address',
      dob: 'Dob',
      note: 'Note',
      gender: 'Gender',
      status: 'Status',
      facility: 'Facility',
      current_company: 'Current Company',
    },
  ]

  private static headerStaff = [
    'stt',
    'key',
    'full_name',
    'username',
    'email',
    'phone',
    'role',
    'status',
  ]

  private static headingStaff = [
    {
      stt: 'STT',
      key: 'Code',
      full_name: 'Full Name',
      username: 'User Name',
      email: 'Email',
      phone: 'Phone',
      role: 'Role',
      status: 'Status',
      job_title: 'Job Title',
      facebook: 'Facebook',
      linkedin: 'Linkedin',
      youtube: 'Youtube',
      describe: 'Describe',
      teacher_status: 'Teacher Status',
    },
  ]

  private static defaultPageSize = 300

  private static getWsName() {
    return 'students'
  }

  private static getWsTeacher() {
    return 'teachers'
  }

  private static getWsStaff() {
    return 'staffs'
  }

  private static getWsEvents() {
    return 'participants'
  }

  private static transformData(data: IStudentDetail, index: number) {
    return {
      full_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      stt: index + 1,
      username: data?.username,
      // class: data?.class,
      status: data?.status,
      // key: data?.key,
      type_user: data?.type_user,
      id: data?.hubspot_contact_id || data?.employee_code,
    }
  }

  private static transformDataTeacher(data: IStudentDetail, index: number) {
    return {
      full_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      stt: index + 1,
      username: data?.username,
      status: data?.status,
      key: data?.key,
      address: data.detail.address,
      note: data.detail.note,
      gender: data?.detail?.sex,
      dob: formatDate(data?.detail?.dob, 'dd/MM/yyyy'),
      current_company: data?.detail?.current_company,
      facility: (data.teacher_facilities
        ? data.teacher_facilities.map((item) => item.facility.name)
        : []
      ).toString(),
    }
  }

  private static headerEventTest = [
    'stt',
    'participant_name',
    'email',
    'phone',
    'result',
    'test',
    'submit_time',
    'source',
  ]

  private static headingEvenTest = [
    {
      stt: 'STT',
      participant_name: 'Participant name',
      email: 'Email',
      phone: 'Phone',
      result: 'Multiple Choice Score',
      test: 'Test Time',
      submit_time: 'Submit Time',
      source: 'Source',
    },
  ]

  private static transformDataEvent(data: any, quiz: any, index: number) {
    return {
      stt: index + 1,
      participant_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      result: data?.quiz_attempts?.[0]?.multiple_choice_score
        ? `${data?.quiz_attempts?.[0]?.multiple_choice_score}/${quiz?.multiple_choice_multiplier}`
        : '--',
      test: data?.quiz_attempts?.[0]?.finished_at
        ? convertTime(data?.quiz_attempts?.[0]?.started_at, data?.quiz_attempts?.[0]?.finished_at)
        : '--',
      source: convertString(data?.quiz_user_instances?.[0]?.source),
      submit_time: data.quiz_attempts?.[0]?.finished_at
        ? format(new Date(data.quiz_attempts?.[0]?.finished_at), 'dd/MM/yyyy HH:mm')
        : '--',
    }
  }

  private static transformDataStaff(data: IStudentDetail, index: number) {
    return {
      full_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      stt: index + 1,
      username: data?.username,
      status: data?.status,
      role: data?.roles?.map((role) => role?.name).join(', '),
      key: data?.key,
    }
  }

  private static transformDataPermission(data: IReportPermission[]): IReportPermission[] {
    return data.map((item) => ({
      ...item,
      relations: item.relations.join(' \n ') as any,
      permissions_parent: item.permissions_parent.join(' \n ') as any,
      module: item.module === null ? '' : (item.module as any),
    }))
  }

  private static autoWidth(worksheet: any, data: IReportPermission[]) {
    const colWidths: any[] = []

    data.forEach((row: any) => {
      Object.keys(row).forEach((key, i) => {
        switch (key) {
          case 'stt':
            colWidths[i] = {
              width: 5,
            }
            break
          case 'group':
            colWidths[i] = {
              width: 40,
            }
            break
          case 'route':
            colWidths[i] = {
              width: 40,
            }
            break
          case 'method':
            colWidths[i] = {
              width: 10,
            }
            break
          default:
            colWidths[i] = {
              width: 30,
            }
        }
      })
    })

    worksheet['!cols'] = colWidths
  }

  static async exportToXlsx(
    gender: string | null,
    status: string | null,
    text: string | null,
    sortType: string | null,
    fromDate: string | null,
    toDate: string | null,
    type_user: string | null | undefined
  ) {
    const ws = XLSX.utils.json_to_sheet(this.heading, {
      header: this.header,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheet(
      ws,
      1,
      gender,
      status,
      text,
      sortType,
      fromDate,
      toDate,
      type_user
    )
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, this.getWsName() + fileExtension)
  }

  static async exportDataPermission() {
    const res = await getPermissionReport()
    if (res?.data) {
      const {
        has_permission,
        has_not_permission,
        group_permission,
      }: {
        has_permission: IReportPermission[]
        has_not_permission: IReportPermission[]
        group_permission: any
      } = res.data
      // Tạo một worksheet từ dữ liệu

      const worksheet1 = XLSX.utils.json_to_sheet(this.transformDataPermission(has_permission), {
        header: this.headerReportPermission,
      })
      this.autoWidth(worksheet1, this.transformDataPermission(has_permission))

      const worksheet2 = XLSX.utils.json_to_sheet(
        this.transformDataPermission(has_not_permission),
        {
          header: this.headerReportPermission,
        }
      )
      this.autoWidth(worksheet2, this.transformDataPermission(has_not_permission))

      const worksheet3 = XLSX.utils.json_to_sheet(group_permission, {
        header: this.headerReportGroupPermission,
      })
      this.autoWidth(worksheet3, group_permission)
      // Tạo một workbook mới
      const workbook = XLSX.utils.book_new()

      // Thêm worksheet vào workbook
      XLSX.utils.book_append_sheet(workbook, worksheet1, 'Đã phân quyền')
      XLSX.utils.book_append_sheet(workbook, worksheet2, 'Chưa phân quyền')
      XLSX.utils.book_append_sheet(workbook, worksheet3, 'Mô tả các nhóm quyền')

      // Xuất file Excel
      XLSX.writeFile(workbook, 'permission.xlsx')
      return
    }
  }

  static async exportToXlsxEvent(
    id: any,
    text: any,
    source: any,
    sortByRatioScore: any
    // sortType: any,
    // status: any
    // fromDate: any,
    // toDate: any
  ) {
    const ws = XLSX.utils.json_to_sheet(this.headingEvenTest, {
      header: this.headerEventTest,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheetEvent(
      id,
      ws,
      1,
      text,
      source,
      sortByRatioScore
      // sortType,
      // status
      // fromDate,
      // toDate
    )
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, this.getWsEvents() + fileExtension)
  }

  private static async addDataToWorksheetEvent(
    id: string,
    ws: XLSX.WorkSheet,
    page_index: number,
    text: string | null,
    source: string | null,
    sortByRatioScore: any
    // sortType: any,
    // status: string | null
    // fromDate: any | null,
    // toDate: any | null
  ) {
    const params = {
      source: source,
      sortByRatioScore: sortByRatioScore,
      text: text,
      // sortType: sortType,
      // status: status,
      // fromDate: fromDate,
      // toDate: toDate,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== null && value !== '')
    )

    const res = await EventTestAPI.getExportParticipants({
      id: id,
      page_index: page_index,
      page_size: this.defaultPageSize,
      params: cleanedParams,
    })
    const wsData = res?.data?.data?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformDataEvent(data, res?.data?.quiz, startIndex + index)
    })

    if (wsData?.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.headerEventTest,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheetEvent(
          id,
          ws,
          page_index + 1,
          text,
          source,
          sortByRatioScore
          // sortType,
          // status
          // fromDate,
          // toDate
        )
      }
    } else return
  }

  static async exportToXlsxTeacher(
    gender: string | null,
    text: string | null,
    status: string | null,
    fromDate: Date | null,
    toDate: Date | null,
    course_category_id: string | null,
    subject_id: string | null
  ) {
    const ws = XLSX.utils.json_to_sheet(this.headingTeacher, {
      header: this.headerTeacher,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheetTeacher(
      ws,
      1,
      gender,
      text,
      status,
      fromDate,
      toDate,
      course_category_id,
      subject_id
    )
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, this.getWsTeacher() + fileExtension)
  }

  static async exportToXlsxStaff(
    status: string | null,
    sortType: string | null,
    text: string | null,
    sortRole: string | null,
    fromDate: Date | null,
    toDate: Date | null,
    gender: string | null
  ) {
    const ws = XLSX.utils.json_to_sheet(this.headingStaff, {
      header: this.headerStaff,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheetStaffs(
      ws,
      1,
      status,
      sortType,
      text,
      sortRole,
      fromDate,
      toDate,
      gender
    )
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, this.getWsStaff() + fileExtension)
  }

  private static async addDataToWorksheet(
    ws: XLSX.WorkSheet,
    page_index: number,
    gender: string | null,
    status: string | null,
    text: string | null,
    sortType: string | null,
    fromDate: string | null,
    toDate: string | null,
    type_user: string | null | undefined
  ) {
    const params = {
      gender: gender,
      text: text,
      status: status,
      sortType: sortType,
      fromDate: fromDate,
      toDate: toDate,
      type_user: type_user,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== null && value !== '')
    )

    const res = await getAPIUserExport({
      page_index: page_index,
      page_size: this.defaultPageSize,
      type: 'STUDENT',
      params: cleanedParams,
    })
    const wsData = res?.data?.users?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformData(data, startIndex + index)
    })

    if (wsData.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.header,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheet(
          ws,
          page_index + 1,
          gender,
          status,
          text,
          sortType,
          fromDate,
          toDate,
          type_user
        )
      }
    } else return
  }

  private static async addDataToWorksheetTeacher(
    ws: XLSX.WorkSheet,
    page_index: number,
    gender: string | null,
    text: string | null,
    status: string | null,
    fromDate: Date | null,
    toDate: Date | null,
    course_category_id: string | null,
    subject_id: string | null
  ) {
    const params = {
      gender: gender,
      text: text,
      status: status,
      fromDate: fromDate,
      toDate: toDate,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== null && value !== '')
    )

    const res = await getAPIUserExport({
      page_index: page_index,
      page_size: this.defaultPageSize,
      type: 'TEACHER',
      params: cleanedParams,
    })
    const wsData = res?.data?.users?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformDataTeacher(data, startIndex + index)
    })

    if (wsData.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.headerTeacher,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheetTeacher(
          ws,
          page_index + 1,
          gender,
          text,
          status,
          fromDate,
          toDate,
          course_category_id,
          subject_id
        )
      }
    } else return
  }

  private static async addDataToWorksheetStaffs(
    ws: XLSX.WorkSheet,
    page_index: number,
    status: string | null,
    sortType: string | null,
    text: string | null,
    sortRole: string | null,
    fromDate: Date | null,
    toDate: Date | null,
    gender: string | null
  ) {
    const params = {
      status: status,
      sortType: sortType,
      text: text,
      sortRole: sortRole,
      fromDate: fromDate,
      toDate: toDate,
      gender: gender,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '' && value !== null)
    )
    const res = await getAPIStaffsExport(page_index, this.defaultPageSize, cleanedParams)
    const wsData = res?.data?.staffs?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformDataStaff(data, startIndex + index)
    })

    if (wsData.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.headerStaff,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheetStaffs(
          ws,
          page_index + 1,
          status,
          sortType,
          text,
          sortRole,
          fromDate,
          toDate,
          gender
        )
      }
    } else return
  }

  static exportDataToExcel = (fileName: string, sheetName: string, data: Object[]) => {
    // Tạo một worksheet từ dữ liệu
    const worksheet = XLSX.utils.json_to_sheet(data)

    // Tạo một workbook mới
    const workbook = XLSX.utils.book_new()

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

    // Xuất file Excel
    XLSX.writeFile(workbook, fileName)
  }
}
