import { Tooltip } from 'antd'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import '../radiobutton/HookFormRadioGroup.scss'
import './HookFormCheckBoxGroup.scss'

interface IHookFormRadioGroupProps {
  name: string
  control: Control<any>
  defaultValue?: unknown
  options: Array<{
    display_name?: string
    key: string | boolean
    description?: string
    group?: string
  }>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  labelClass?: string
  labelClassChecked?: string
  disabled?: boolean
  className?: string
  classItem?: string
  showToolTip?: boolean
}

const HookFormCheckBoxGroup = ({
  name,
  control,
  defaultValue,
  options,
  onChange,
  labelClass = '',
  labelClassChecked = '',
  disabled,
  className = '',
  classItem = '',
  showToolTip = false,
}: IHookFormRadioGroupProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <div className={`${className}`}>
              {options.map((option, index) => {
                const checked: boolean = field.value?.includes(option.key.toString())
                return (
                  <div className={`${classItem}`} key={option.key.toString() + index}>
                    <div className='d-flex fv-row'>
                      <label className='form-check form-check-solid sapp-form-check-custom ps-9 cursor-pointer d-flex align-items-center'>
                        <input
                          {...field}
                          disabled={disabled}
                          onChange={(event: React.ChangeEvent<any>) => {
                            let arr = [] as any
                            if (field.value?.length > 0) {
                              arr = [...field.value]
                              if (arr.includes(event.target.value)) {
                                const newArr = arr.filter((e: any) => e !== event.target.value)
                                arr = [...newArr]
                              } else {
                                arr.push(event.target.value)
                              }
                            } else {
                              arr.push(event.target.value)
                            }
                            field.onChange(arr)
                            onChange && onChange(arr)
                          }}
                          className={`form-check-input bg-gray-300 me-2 cursor-pointer`}
                          type='checkbox'
                          key={option.display_name}
                          value={option.key.toString()}
                          checked={field.value?.includes(option.key.toString())}
                        />
                        <span className='form-check-label ps-1 d-block'>
                          <div
                            className={`d-flex align-items-center ${
                              checked
                                ? `sapp-text-primary ${
                                    labelClassChecked ? labelClassChecked : 'fw-semibold fs-6 lh-1'
                                  }`
                                : `sapp-box-no-check ${
                                    labelClass ? labelClass : 'fw-semibold lh-1'
                                  }`
                            }`}
                          >
                            {showToolTip && (
                              <Tooltip title={option.description} color='#ffffff' placement='right'>
                                {option.display_name}
                              </Tooltip>
                            )}
                          </div>
                        </span>
                      </label>
                    </div>
                  </div>
                )
              })}
            </div>
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )
      }}
    />
  )
}

export default HookFormCheckBoxGroup
