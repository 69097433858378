import clsx from 'clsx'
import { TITLE_OVERVIEW_EXAM } from 'src/constants/exam'
import { useExam } from 'src/context/ExamContext'
import { formatDate } from 'src/utils/time'
import ItemProfile from '../../base/ItemProfile'
import styles from './ExamDetail.module.scss'
import { EXAM_PROGRAM } from 'src/type'
import { Link } from 'react-router-dom'
import { PageLink } from 'src/constants'
import { useUserContext } from 'src/context/UserProvider'
import { TITLE_EXAM_GR } from 'src/constants/permission'
import { hasPermission } from 'src/utils/permission'

const ExamOverview = () => {
  const { data, program } = useExam()
  const { profileMe } = useUserContext()

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_EXAM_GR.EDIT_EXAM)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0 d-flex justify-content-between w-100'>
          <h3 className='fw-bold m-0'>Overview</h3>
          {allowRenderEdit && (
            <Link
              to={`${PageLink.EXAMS}/${program}/${data?.id}/setting`}
              className='btn btn-sm btn-primary align-self-center px-5 py-3'
            >
              Edit Exam Info
            </Link>
          )}
        </div>
      </div>
      <div className='card-body p-9'>
        <ItemProfile title={TITLE_OVERVIEW_EXAM.name} body={`${data?.name || '-'}`} />

        {program === EXAM_PROGRAM.CFA && (
          <>
            <ItemProfile
              title={TITLE_OVERVIEW_EXAM.registration_opening_date}
              body={`${formatDate(data?.registration_opening_date)}`}
            />
            <ItemProfile
              title={TITLE_OVERVIEW_EXAM.early_registration_deadline}
              body={`${formatDate(data?.early_registration_deadline)}`}
            />
            <ItemProfile
              title={TITLE_OVERVIEW_EXAM.standard_registration_deadline}
              body={`${formatDate(data?.standard_registration_deadline)}`}
            />
          </>
        )}
        {program === EXAM_PROGRAM.CMA && (
          <>
            <ItemProfile
              title={TITLE_OVERVIEW_EXAM.registration_closing_date}
              body={`${formatDate(data?.registration_closing_date)}`}
            />
          </>
        )}

        <ItemProfile title={TITLE_OVERVIEW_EXAM.exam_schedule} />
        <ul className={styles.examSchedule}>
          {data?.examination_subjects?.map((exam) => (
            <li className={clsx('row mb-7')} key={exam.id}>
              <label
                className={clsx(
                  'col col-md-3 fw-semibold text-muted text-black',
                  styles.scheduleTitle
                )}
              >
                {exam?.subject.name}
              </label>

              <div className='col col-md-8'>
                <span className='fw-semibold fs-6'>
                  {(program === EXAM_PROGRAM.CFA || program === EXAM_PROGRAM.CMA) && (
                    <span>
                      {formatDate(exam?.start_date, '')} - {formatDate(exam?.end_date)}
                    </span>
                  )}
                  {program === EXAM_PROGRAM.ACCA && formatDate(exam?.end_date)}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ExamOverview
