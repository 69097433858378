import { Tooltip } from 'antd'
import clsx from 'clsx'
import { HTMLProps } from 'react'
import { Controller } from 'react-hook-form'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import { ICourseCategory, PROGRAM } from 'src/type/courses'
import styles from './TeacherPostForm.module.scss'

interface ISubjectTemplate extends HTMLProps<HTMLDivElement> {
  id?: string
  category?: ICourseCategory
  handleRadioChange: any
  control: any
  title: string
}
const primaryResponsibilityRestraint = (name: string | undefined) => (
  <span>
    Select <u>at least one</u> subject to mark <strong>{name || 'this'}</strong> as Primary
    Responsibility.
  </span>
)

const SubjectTemplate = ({
  id,
  category,
  handleRadioChange,
  control,
  children,
  title,
}: ISubjectTemplate) => {
  return (
    <div
      className={clsx('d-flex flex-column flex-row-fluid gap-7 gap-lg-10', {
        'mt-7': !id || title === PROGRAM.ACCA || title === PROGRAM.CERT_DIP,
        'mt-8': id && title === PROGRAM.CMA,
      })}
    >
      <div
        className={'card'}
        style={{
          border: id && '1px solid #E1E3EA',
        }}
      >
        <div
          className={clsx('d-flex w-100 flex-column card-body', {
            'gap-6': title !== PROGRAM.ACCA && title !== PROGRAM.CERT_DIP,
          })}
        >
          <div className='d-flex flex-row justify-content-between flex-grow-1'>
            <h1 className={styles.strong}>{title}</h1>
            <Tooltip title={primaryResponsibilityRestraint(category?.name)} color='white'>
              <div className='d-flex justify-content-between align-items-center gap-3'>
                <label>Primary Responsibility</label>
                <Controller
                  name='category_primary_id'
                  control={control}
                  render={({ field }) => {
                    return (
                      <SAPPRadio
                        checked={field.value === category?.id || false}
                        onChange={() => handleRadioChange(category?.id || '')}
                      />
                    )
                  }}
                />
              </div>
            </Tooltip>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SubjectTemplate
