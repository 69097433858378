import { PageLink } from './pageLink'

export const STAFF_PROFILE = '/staff/profile'
export const STUDENT_PROFILE = '/student/profile'
export const TEACHER_PROFILE = '/teacher/profile'

export const MY_PROFILE = {
  OVERVIEW: 'overview',
  SETTING: 'setting',
  SECURITY: 'security',
  ACTIVITY: 'activity',
  BILLING: 'billing',
  STATEMENTS: 'statements',
  CERTIFICATE: 'certificate',
  API_KEY: 'api-keys',
  LOGS: 'logs',
  COURSE: 'course',
  CLASS: 'class',
  REFFERRALS: 'refferrals',
}

export const AppConfig = {
  imageFileTypeWhiteList: [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'video/mp4',
    'audio/mav',
    ' video/vid',
  ],
}

export const ACCEPT_UPLOAD_MIME_TYPE = 'image/jpg, image/jpeg, image/gif, image/png'

export const ACCEPT_UPLOAD_VIDEO_MINE_TYPE = 'video/mp4, audio/mav, video/vid, video/mov'

export const DEFAULT_MAX_FILE_SIZE = 20

export const FORMAT_DATE_COUNT_DOWN = 'MMMM dd yyyy HH:mm:ss z'

export const DENIED_PERMISSIONS = 'Tạm thời bạn không có quyền thực hiện thao tác này'
export const DENIED_MESSAGES = 'Tạm thời bạn không có quyền xem phần này'
export const VALIDATION_FIELD = 'This field is required'
export const VALIDATION_EMPTY_FIELD = 'This field cannot be empty'
export const VALIDATION_MIN = (min: number) => `Only numbers > ${min} are allowed.`
export const VALIDATION_MIN_EQUAL = (min: number) => `Only numbers >= ${min} are allowed.`
export const VALIDATE_TIME_MINUTE = 'Only numbers (0 - 59) are allowed'
export const VALIDATE_TIME = 'At least one field should be > 1'
export const VALIDATE_FILED_MAX = 'Tab name should be less than 1000 charater'
export const VALIDATE_GROUP_NAME = 'Group name should be less than 1000 charater'
export const VALIDATE_FIELD_MAX_LENGTH = (field: string, max: number) =>
  `${field} should be less than ${max} character`
export const VALIDATE_NUMBER_REGEX = /^(?:[0-9]+)?$/

export const FILTER_SORTBY = [
  // {
  //   label: 'All',
  //   value: '',
  // },
  {
    label: 'A-Z',
    value: 'A_Z',
  },
  {
    label: 'Z-A',
    value: 'Z_A',
  },
  {
    label: 'Latest',
    value: 'LATEST',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
  },
]

export const FILTER_SORTBY_DATE = [
  {
    label: 'Latest',
    value: 'LATEST',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
  },
]

export const FILTER_SELECTALL_SORTBY = [
  // {
  //   label: 'All',
  //   value: 'all',
  // },
  {
    label: 'A-Z',
    value: 'A_Z',
  },
  {
    label: 'Z-A',
    value: 'Z_A',
  },
  {
    label: 'Latest',
    value: 'LATEST',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
  },
]

export const STATUS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Block',
    value: 'BLOCKED',
  },
]
export const STATUS_TEST = [
  {
    label: 'Finish',
    value: 'FINISH',
  },
  {
    label: 'UnFinish',
    value: 'UNFINISH',
  },
]

export const STATUS_FORM = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Block',
    value: 'BLOCKED',
  },
  // {
  //   label: 'Inactive',
  //   value: 'INACTIVE',
  // },
]

export const FILTER_CLASS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'ACCA',
    value: 'ACCA',
  },
  {
    label: 'CFA',
    value: 'CFA',
  },
]

export const FILTER_EXAM = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'ACCA',
    value: 'ACCA',
  },
  {
    label: 'CFA',
    value: 'CFA',
  },
  {
    label: 'CMA',
    value: 'CMA',
  },
]

export const GENDER_FORM = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Other',
    value: 'OTHERS',
  },
]

export const GENDER = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
]
export const USERTYPE = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Giáo viên',
    value: 'TEACHER',
  },
  {
    label: 'Học viên',
    value: 'STUDENT',
  },
]

export const MESSAGE_VALIDATION_USERNAME =
  'Input at least 6 characters without space between words and special characters (except 3 characters . - _)'

export const FORMAT_DATETIME = 'dd/MM/yyyy HH:mm'
export const VALIDATE_PHONE = 'Phone numbers include 10 or 11 digits and starting with 0'
export const VALIDATE_PASSWORD =
  'Password must contain at least 8 characters, 1 uppercase character, 1 number'

export const GUIDELINE_USERNAME = [
  'Tối thiểu 6 ký tự, tối đa 40 ký tự',
  'Không chứa khoảng trắng, ký tự tiếng Việt có dấu và ký tự đặc biệt (ngoại trừ 3 ký tự . - _)',
  `Không trùng username đã tồn tại`,
]

export const GUIDELINE_EMAIL = [
  'Nhập đúng định dạng email abc@abc.abc',
  'Không trùng email đã tồn tại',
  'Mã OTP đã gửi về email của bạn. Vui lòng lấy mã OTP và gửi cho admin để xác nhận đổi email mới',
]

export const GUIDELINE_PHONE = [
  'Chỉ nhập ký tự số',
  'Bắt đầu là ký tự 0',
  'Chỉ gồm 10 hoặc 11 ký tự',
  'Không trùng SĐT đã tồn tại',
]

export const GUIDELINE_PASSWORD = ['Tối thiểu 8 ký tự, ít nhất 1 ký tự hoa, 1 ký tự số']
export const GUIDELINE_FULLNAME = [
  'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, tối đa 100 ký tự',
]
export const GUIDELINE_HUBSPOT_CONTACT_ID = [
  'Mã học viên chính là Record ID của học viên trên Hubspot, yêu cầu nhập chính xác để tiện truy vấn sau này.',
]
export const GUIDELINE_ADDRESS = [
  'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, tối đa 255 ký tự',
]

export const pageSizeOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

export const DEFAULT_SELECT_ALL = [
  {
    label: 'All',
    value: 'all',
  },
]

export const BREAKPOINT = {
  xs: 300,
  md: 768,
  lg: 960,
  xl: 1140,
}

export const COURSE_TYPE_VALUE = {
  FOUNDATION_COURSE: 'FOUNDATION_COURSE',
  TRIAL_COURSE: 'TRIAL_COURSE',
  PRACTICE_COURSE: 'PRACTICE_COURSE',
  NORMAL_COURSE: 'NORMAL_COURSE',
}

export const COURSE_TYPES = [
  {
    label: 'Foundation Course',
    value: 'FOUNDATION_COURSE',
  },
  {
    label: 'Trial Course',
    value: 'TRIAL_COURSE',
  },
  {
    label: 'Practice Course',
    value: 'PRACTICE_COURSE',
  },
  {
    label: 'Normal Course',
    value: 'NORMAL_COURSE',
  },
]
export const COURSE_TYPES_CLASS = [
  {
    label: 'Trial Course',
    value: 'TRIAL_COURSE',
  },
  {
    label: 'Practice Course',
    value: 'PRACTICE_COURSE',
  },
  {
    label: 'Normal Course',
    value: 'NORMAL_COURSE',
  },
]
export const COURSE_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Publish',
    value: 'PUBLISH',
  },
  {
    label: 'Lock',
    value: 'LOCK',
  },
  {
    label: 'Block',
    value: 'BLOCK',
  },
]
export const TYPE_COURSE = {
  SECTION: 'SECTION',
  SUBSECTION: 'SUBSECTION',
  UNIT: 'UNIT',
  ACTIVITY: 'ACTIVITY',
  PART: 'PART',
  CHAPTER: 'CHAPTER',
  PARTIALCHAPTER: 'PARTIALCHAPTER',
  PARTIALACTIVITY: 'PARTIALACTIVITY',
  LEARNINGOUTCOME: 'LEARNINGOUTCOME',
  FINAL_TEST: 'FINAL_TEST',
  MID_TERM_TEST: 'MID_TERM_TEST',
  PART_TEST: 'PART_TEST',
  CASE_STUDY: 'CASE_STUDY_STORY',
  STORY: 'STORY',
}
export const TYPE_DOCUMENT = {
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
  QUIZ: 'QUIZ',
  PAST_EXAM_ANALYSIS: 'PAST_EXAM_ANALYSIS',
}

export const VALIDATE_MIN_FULLNAME = 'Fullname must contain at least 3 character(s)'
export const VALIDATE_MAX_FULLNAME = 'Fullname must contain at most 100 character(s)'
export const VALIDATE_MIN_USERNAME = 'Username must contain at least 6 character(s)'
export const VALIDATE_MIN_HUBSPOT_CONTACT_ID = 'Code must contain at least 3 character(s)'
export const VALIDATE_MAX_HUBSPOT_CONTACT_ID = 'Code must contain at most 20 character(s)'
export const pageSizeOptionsDetail = [
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

export const COURSE_STATUS_FORM = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Publish',
    value: 'PUBLISH',
  },
  {
    label: 'Lock',
    value: 'LOCK',
  },
  {
    label: 'Block',
    value: 'BLOCK',
  },
]

export const ENTRANCETEST_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Active',
    value: 'PUBLISHED',
  },
  {
    label: 'Lock',
    value: 'LOCKED',
  },
]

export const SHOP_PRODUCT_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Published',
    value: 'PUBLISHED',
  },
  {
    label: 'Lock',
    value: 'LOCK',
  },
  // {
  //   label: 'Timer',
  //   value: 'TIMER',
  // },
]

export const VALIDATE_CODE_MIN = 'Code must contain at least 1 character(s)'
export const VALIDATE_CODE_MAX = 'Code must contain at most 255 character(s)'
export const VALIDATE_NAME_MIN = 'Name must contain at least 1 character(s)'
export const VALIDATE_NAME_MAX = 'Name must contain at most 1000 character(s)'
export const VALIDATE_CATEGORY_MIN = 'Category must contain at least 1 character(s)'
export const VALIDATE_NUMBER = 'Only whole numbers allowed'
export const VALIDATE_SETTING = 'Only numbers (0 - 100) are allowed'
export const VALIDATE_DURATION = 'Only numbers (1 - 999) are allowed'
export const VALIDATE_DURATION_9999 = 'Only numbers not less than 1 are allowed'
export const VALIDATE_DIFFICULTY = 'Only numbers (1 - 50) are allowed'
export const VALIDATE_QUESTIONS = 'Only numbers >= 1 are allowed.'
export const VALIDATE_PASS_POINT = 'Only numbers (1 - 100) are allowed'
export const VALIDATE_PASS_NOTE = 'Note must contain at least 1 character(s)'
export const VALIDATE_SUBJECT_MIN = 'Subject must contain at least 1 character(s)'
export const VALIDATE_SUBJECT_MAX = 'Subject must contain at most 50 character(s)'
export const VALIDATE_MINUTES = 'Only numbers >=0 are allowed'
export const VALIDATE_TYPE = 'Type must contain at least 1 character(s)'
export const GUIDELINE_NAME = [VALIDATE_NAME_MIN, VALIDATE_NAME_MAX]
export const GUIDELINE_CODE = [VALIDATE_CODE_MIN, VALIDATE_CODE_MAX]
export const GUIDELINE_DURATION = [VALIDATE_NUMBER]
export const GUIDELINE_LEVEL = [VALIDATE_NUMBER, VALIDATE_DIFFICULTY]
export const GUIDELINE_SETTING = [VALIDATE_NUMBER, VALIDATE_SETTING]
export const GUIDELINE_PASSPOINT = [VALIDATE_NUMBER, VALIDATE_PASS_POINT]
export const VALIDATE_FOLDER_NAME = `The file name cant contain any of the following characters: \\:*?"<>|`
export const VALIDATE_MAX_NUMBER = 9999
export const TIME_OF_DAY = 24 * 60 * 60 * 1000

export const MOCKUP_HEADER = [
  'Order',
  'Name',
  'Category',
  'Type',
  'Create At',
  'Update at',
  'Action',
  'Code',
  'Exam',
]
export const HEADER_CLASS_LIST = [
  'Order',
  'Class',
  'Foundation',
  'Program',
  'Schedule',
  'Opening',
  'Status',
  'Note',
]
export const HEADER_ENTRANCE_TEST_LIST = [
  'Order',
  'Test name',
  'Program',
  'Subject',
  'Create At',
  'Submissions count',
]

export const VALIDATE_MIN_NAME = 'Name must contain at least 1 character(s)'
export const VALIDATE_MAX_NAME = 'Name must contain at most 100 character(s)'
export const VALIDATE_MAX_DESCRIPTION = 'Description must contain at most 2000 character(s)'

export const DURATION_EMAIL = 1800
export const DURATION_RESEND_EMAIL = 15 //miliseconds
export const OPTIONS_OF_NOTITYPE = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Notifications',
    value: 'notifications',
  },
]

export const SELECT_TYPE = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Selected',
    value: 'SELECTED',
  },
  {
    label: 'Not Selected',
    value: 'NOT_SELECTED',
  },
]

export const IS_STAFF = [
  {
    label: 'No',
    value: 'false',
  },
  {
    label: 'Yes',
    value: 'true',
  },
]
export const MAX_IMAGE_PREVIEW_META = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Large',
    value: 'large',
  },
]

export const INDEPENDENT_QUESTION = 'Câu hỏi tự do'

export const CLASS_USER_TYPES = {
  NORMAL: 'Bình Thường',
  RESERVED: 'Bảo Lưu',
  MOVED_OUT: 'Đã Chuyển Đi',
  MOVED_IN: 'Chuyển Đến',
  TRANSFER_TO: 'Đã chuyển Nhượng',
  TRANSFERRED: 'Được Chuyển Nhượng',
  CANCELED: 'Hủy Học',
}

export const LEARNING_STATUS = [
  {
    label: 'Ready To Learn',
    value: 'READY_TO_LEARN',
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Expired',
    value: 'CANCELED',
  },
]

export const CLASS_TYPE = {
  LESSON: 'Lesson',
  REVISION: 'Revision',
  FOUNDATION: 'Foundation',
  TRIAL: 'Trial',
}

export const MAIL_LOG_STATUS = [
  {
    label: 'Đã gửi',
    value: 'SENT',
  },
  {
    label: 'Thất bại',
    value: 'FAIL',
  },
]
export const ACTION_TYPE = {
  EDIT: 'edit',
  CREATE: 'create',
}

export const DEBOUNCED_NUMBER = 500

// SHOP COURSE
export const FAQ_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Publish',
    value: 'PUBLISHED',
  },
]

export const SHOP_BLOG_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Timer',
    value: 'TIMER',
  },
  {
    label: 'Publish',
    value: 'PUBLISHED',
  },
]

export const SHOP_EVENT_STATUS = [
  {
    label: 'Lock',
    value: 'LOCKED',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Publish',
    value: 'PUBLISHED',
  },
]

export const video_url = process.env.NEXT_PUBLIC_VIDEO_URL
export const PARTICIPANT_ENUM = {
  MANUAL: 'MANUAL',
  MANUALLY: 'Manually',
  FORM: 'Form',
  FORM_REGISTER: 'FORM_REGISTER',
}

export const FILTER_SOURCE = [
  {
    label: 'Manual',
    value: 'MANUAL',
  },
  {
    label: 'Form Register',
    value: 'FORM_REGISTER',
  },
]

export const SORT_BY = 'Source By'

export const FILTER_SORT_RESULT = [
  {
    label: 'Ascending',
    value: 'ASCENDING',
  },
  {
    label: 'Descending',
    value: 'DESCENDING',
  },
]

export const STATUS_EVENT_TEST = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Deactive',
    value: 'DEACTIVE',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Block',
    value: 'BLOCKED',
  },
  {
    label: 'Locked',
    value: 'LOCKED',
  },
  {
    label: 'Publish',
    value: 'PUBLISHED',
  },
  // {
  //   label: 'Inactive',
  //   value: 'INACTIVE',
  // },
]

export const HEADER_CACHE_LIST = ['Cache name', 'Endpoint', 'Action']

export const QUIZ_GRADING_METHOD = [
  {
    label: 'Yes',
    value: 'MANUAL',
  },
  {
    label: 'No',
    value: 'AUTO',
  },
]

export const QUIZ_ATTEMPT_STATUS = {
  UN_SUBMITTED: 'UN_SUBMITTED', // đổi thành IN_PROGRESS, / tạo mới bản bản ghi quiz attempt hoặc đóng trình duyệt khi đang làm
  UN_FINISHED: 'UN_FINISHED', // đổi thành UN_SUMMITTED, / click nút quit
  SUBMITTED: 'SUBMITTED', // nộp bài (click Finish), dành cho bài ko chấm ko chấm
  AWAITING_GRADING: 'AWAITING_GRADING', // dành cho bài chấm điểm, làm bài xong và đang đợi chấm
  FINISHED_GRADING: 'FINISHED_GRADING', // dành cho bài chấm điểm, bài đã được chấm xong
}

export const CONFIRM_CHANGE_DURATION_QUIZ =
  'Update Duration of Class will change the access time of Test/Quiz. Do you want to save the change?'

export const QUIZ_ATTEMPT_STATUS_AUTO = [
  {
    label: 'Unsubmitted',
    value: 'UN_SUBMITTED',
  },
  {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
]

export const QUIZ_ATTEMPT_STATUS_MANUAL = [
  {
    label: 'Unsubmitted',
    value: 'UN_SUBMITTED',
  },
  {
    label: 'Awating Grading',
    value: 'AWAITING_GRADING',
  },
  {
    label: 'Finish Grading',
    value: 'FINISHED_GRADING',
  },
]

export const FACILITY_STATUS = [
  {
    value: 'ACTIVE',
    label: 'Đang hoạt động',
  },
  {
    value: 'CLOSE',
    label: 'Ngừng hoạt động',
  },
]

export const FACILITY_STATUS_ENUM = {
  ACTIVE: 'ACTIVE',
  CLOSE: 'CLOSE',
}

export const ROOM_TYPE_ENUM = {
  STANDARD: 'STANDARD',
  ROUND_TABLE: 'ROUND_TABLE',
}

export const CLASSROOM_TYPE = [
  {
    value: 'STANDARD',
    label: 'Tiêu Chuẩn',
  },
  {
    value: 'ROUND_TABLE',
    label: 'Bàn tròn',
  },
]

export const STUDENT_STATUS = [
  {
    label: 'Activated',
    value: 'ACTIVATED',
  },
  {
    label: 'Inactivated',
    value: 'INACTIVATED',
  },
]

export const DURATION_TIME_TYPE = {
  FLEXIBLE: 'FLEXIBLE',
  FIXED: 'FIXED',
}

export const formatDateTime = 'dd/MM/yyyy HH:mm'

export const ACCOUNT_TYPE = [
  {
    label: 'Employee Account',
    value: 'EMPLOYEE',
  },
  {
    label: 'Student Account',
    value: 'STUDENT',
  },
]

export * from './pageLink'
export * from './common'
export * from './titleSidebar'
export * from './queryKeys'
