import { IResponse } from 'preview-activity/dist/shared/interfaces'
import { fetcher } from 'src/services/request'
import { IDepartment, IDepartmentForm, IDepartmentsRes, IDepartmentTree } from 'src/type'
import { DEPARTMENT_TYPES } from 'src/type/department/enum'

export class DepartmentAPI {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IDepartment[]>> {
    return fetcher(`departments?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getTree({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IDepartmentsRes>> {
    return fetcher(`departments?page_index=${page_index}&page_size=${page_size}`, {
      params: {
        ...params,
        tree_view: true,
      },
    })
  }

  static getDetail(id: string): Promise<IResponse<IDepartmentTree>> {
    return fetcher(`departments/${id}`)
  }

  static getParent(page_index: number, page_size: number | undefined, type: DEPARTMENT_TYPES) {
    return fetcher(
      `/departments/parent-list?page_index=${page_index}&page_size=${page_size}&type=${type}`
    )
  }

  static create(data: IDepartmentForm) {
    return fetcher(`/departments`, {
      method: 'POST',
      data: data,
    })
  }

  static update(id: string, data: IDepartmentForm) {
    return fetcher(`/departments/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static delete(department_ids: string[]): Promise<IResponse<any>> {
    return fetcher('departments', {
      method: 'DELETE',
      data: {
        department_ids,
      },
    })
  }
}
