import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import RenderRoomSection from 'src/components/classroom/room/detail/RenderRoomSection'
import RoomCard from 'src/components/classroom/room/detail/RoomCard'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ROOM_PROFILE, ROOM_PROFILE_URL, TITLE_OPTIONS_ROOM } from 'src/constants/classroom'
import 'src/pages/classes/detail/ClassDetail.scss'
import { ITabs } from 'src/type'
import { IRoom } from 'src/type/area'

const RoomDetail = () => {
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.CLASSROOM_ROOM}`,
      title: `${TITLE_OPTIONS_ROOM.classroomlist}`,
    },
    {
      link: '',
      title: `${TITLE_OPTIONS_ROOM.classroom}`,
    },
  ]

  const tabs: ITabs[] = [
    {
      link: `${ROOM_PROFILE}/${id}/${ROOM_PROFILE_URL.OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${ROOM_PROFILE}/${id}/${ROOM_PROFILE_URL.SETTING}`,
      title: 'Settings',
    },
  ]

  const [roomDetail, setAreaDetail] = useState<IRoom | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const fetchRoomDetail = async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const res = await ClassroomApi.getRoomDetail(id)
      setAreaDetail(res?.data)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchRoomDetail()
  }, [])

  const refetch = () => {
    fetchRoomDetail()
  }

  return (
    <PageLayouts
      pageTitle='Room Detail'
      breadcrumbs={breadcrumbs}
      classNameLayout='sapp-overflow-y-layout'
    >
      <RoomCard roomDetail={roomDetail} tabs={tabs} loading={isLoading} />
      <RenderRoomSection roomDetail={roomDetail} loading={isLoading} refetch={() => refetch()} />
    </PageLayouts>
  )
}

export default withAuthComponents(RoomDetail)
