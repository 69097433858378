import { IRoles } from 'src/type/roles'
import PermissionInfo from 'src/components/roles/detail/PermissionInfo'
import { useConfirm } from 'src/hooks/use-confirm'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_ROLE_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { IMyRole } from 'src/type/profile'
import { Collapse, ConfigProvider } from 'antd'
import styles from './PermissionDetail.module.scss'
import clsx from 'clsx'
interface IProps {
  role: IRoles | undefined
  deleteRole: (role_code: string) => void
  setOpen: any
  staffCount: number
}

const PermissionDetail = ({ role, deleteRole, setOpen, staffCount }: IProps) => {
  const { confirm, contextHolder } = useConfirm()

  const handleRemove = (roleId: string) => {
    confirm({
      okButtonCaption: 'Có',
      cancelButtonCaption: 'Không',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => deleteRole(roleId),
    })
  }
  const { profileMe } = useUserContext()
  const allowRenderDeleteRoles = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.REMOVE_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEditRoles = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.EDIT_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const groupedData: Record<string, IMyRole[]> = (role?.permissions || []).reduce(
    (acc: Record<string, IMyRole[]>, item) => {
      if (!acc[item.group]) {
        acc[item.group] = []
      }
      acc[item.group].push({
        key: item.key,
        display_name: item.display_name,
        description: item.description,
        group: item.group,
      })
      return acc
    },
    {}
  )

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            /* here is your component tokens */
            contentBg: '',
            headerBg: '',
          },
        },
      }}
    >
      <div className='sapp-permission-detail col-md-12 col-xl-4 sapp-h-max'>
        {contextHolder}
        <div className='card card-flush h-md-100'>
          <div className='card-header px-8'>
            <div className='card-title'>
              <h2 className='text-gray-800 sapp-fs-18'>{role?.name}</h2>
            </div>
          </div>
          <div className='card-body px-8 pt-1'>
            <div className='fw-bold text-gray-600 mb-5'>
              Total users with this role: {staffCount}
            </div>
            <div className='collapse show'>
              {Object.entries(groupedData).map(([groupName, items]) => (
                <Collapse
                  key={groupName}
                  bordered={false}
                  items={[
                    {
                      key: '1',
                      label: (
                        <label className='fs-6 fw-semibold form-label mb-0 text-capitalize text-gray-600'>
                          {groupName.replace(/(_management)|_/g, ' ')} ({items.length})
                        </label>
                      ),
                      children: (
                        <div className={styles.permissionInfoContainer}>
                          {items.map((item) => (
                            <PermissionInfo key={item.key} title={item.display_name} />
                          ))}
                        </div>
                      ),
                    },
                  ]}
                  className={clsx(styles.collapse)}
                />
              ))}
            </div>
          </div>
          <div className='d-flex flex-wrap flex-xxl-nowrap align-items-center justify-content-start px-9 pt-2 pb-7 gap-10'>
            {allowRenderEditRoles && (
              <div
                className='btn btn-light btn-active-primary sapp-padding-btn-gray sapp-font-import-student d-flex align-items-center sapp-role-btn-icon fw-semibold sapp-h-40'
                onClick={() => setOpen(true)}
              >
                Edit Role
              </div>
            )}
            {allowRenderDeleteRoles && (
              <div
                className='sapp-font-import-student text-danger cursor-pointer'
                onClick={() => {
                  role?.id && handleRemove(role?.id as string)
                }}
              >
                Delete
              </div>
            )}
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default PermissionDetail
