import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IStudentList } from 'src/type/students'
import LoadingTable from 'src/common/LoadingTable'
import { FILTER_SELECTALL_SORTBY, STUDENT_PROFILE } from 'src/constants'
import { useForm } from 'react-hook-form'
import { Select } from 'antd'
import { Link } from 'react-router-dom'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { replaceValueAll } from 'src/utils/string'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import { PROGRAM } from 'src/type/courses'
import { LANG_USER } from 'src/constants/lang'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  userState: IStudentList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchListUsers: (
    type: 'STUDENT',
    currentPage?: number,
    pageSize?: number,
    params?: Object
  ) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  type: 'STUDENT'
}

// define headers
const headers = [
  {
    label: 'ID',
    className: 'min-w-150px',
  },
  {
    label: 'STUDENT NAME',
    className: 'min-w-200px',
  },
  {
    label: 'EMAIL',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'LEVEL',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'PHONE',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Account Type',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const TableUsers = ({
  userState,
  loading,
  setLoading,
  fetchListUsers,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  type,
}: IProps) => {
  const initialValues: any = {
    text: '',
    status: 'ACTIVE',
    sortType: '',
    fromDate: '',
    toDate: '',
    dateField: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(searchParams.fromDate)
  const dateQueryToDate = getDateInfo(searchParams.toDate)

  const showSearchParams =
    searchParams.text || searchParams.sortType || searchParams.fromDate || searchParams.toDate

  const validationSchema = z.object({
    text: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })
  const fieldNames = ['text', 'status', 'sortType']

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.status,
        searchParams?.sortType,
        searchParams?.examType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showSearchParams ? 'updated_at' : 'created_at'
      )
    )
    fetchListUsers(type, page_index, page_size, cleanedParams)
  }
  useEffect(() => {
    setLoading(true)

    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.status,
        searchParams?.sortType,
        searchParams?.examType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
          : ''
      )
    )
    fetchListUsers(type, 1, 10, cleanedParams)
  }, [])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    const cleanedParams = cleanParamsAPI(
      getParams('', searchParams?.status, '', '', '', 'created_at')
    )
    setSearchParams(initialValues)
    setLoading(true)
    fetchListUsers(type, 1, 10, cleanedParams)
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trim()),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    fetchListUsers(type, 1, queryParams?.page_size || 10, { ...cleanedParams })
  }

  const classType = userState?.program
  const getLevel = (() => {
    switch (classType) {
      case PROGRAM.ACCA:
        return 'acca_level'
      case PROGRAM.CFA:
        return 'level'
      case PROGRAM.CMA:
        return 'cma_level'
      default:
        return null
    }
  })()

  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search'
                  defaultValue={queryParams?.text}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}

            <div className='col-xl-3 col-sm-4 mt-2 d-none'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='status'
                placeholder='Status'
                defaultValue={'ACTIVE'}
                className='sapp-fs-select fs-6'
              >
                <Option key={'Active'} value={'ACTIVE'}>
                  Active
                </Option>
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
                className='sapp-fs-select fs-6'
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={userState?.users}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {userState?.users?.map((user: any, index: number) => {
              const isChecked = checkedList.includes(user.id)

              return (
                <tr key={user.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(user.id!)
                      }}
                    />
                  </td>
                  <td className='text-start'>{user?.hubspot_contact_id || user?.employee_code || '--'}</td>
                  <td className='text-start'>
                    <Link
                      className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                      to={`${STUDENT_PROFILE}/${user?.id}/overview`}
                    >
                      {user?.detail?.full_name}
                    </Link>
                  </td>
                  <td className='text-start'>{user?.user_contacts[0]?.email}</td>
                  <td>{getLevel ? user?.detail?.[getLevel] || '-' : '-'}</td>
                  <td className='text-start'>{user?.user_contacts[0]?.phone}</td>
                  <td className='text-start'>
                    {user?.type_user === 'STUDENT' ? 'Student' : 'Employee'}
                  </td>
                  <td className='text-start'>
                    <DatetimeColumn created_at={user?.created_at} updated_at={user?.updated_at} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={userState?.metadata?.page_index || 1}
        pageSize={userState?.metadata?.page_size || 10}
        totalItems={userState?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableUsers
