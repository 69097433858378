import PDFViewer from 'src/components/base/FileViewer'
import SappModal from 'src/components/base/SappModal'

interface Iprops {
  open: boolean
  setOpen: any
  url?: string
}
const ModalPreviewFile = ({ open, setOpen, url }: Iprops) => {
  const handleClose = () => {
    setOpen({ status: false, url: undefined })
  }
  return (
    <SappModal
      enforceFocus={false}
      open={open}
      handleClose={handleClose}
      title={''}
      showFooter={false}
      dialogClassName={
        'm-0  modal-dialog-scrollable sapp-modal-createTab modal-fullscreen modal-fullscreen-form'
      }
      classNameBody={'sapp-m-h-unset h-100'}
      classNameContent='h-100'
    >
      <PDFViewer file={url || ''} />
    </SappModal>
  )
}
export default ModalPreviewFile
