import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { UseFormReturn, UseFormTrigger } from 'react-hook-form'
import { ShopCategoryAPI } from 'src/apis/short-course/blogs/category'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { SHOP_BLOG_STATUS } from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import useAuthorList from 'src/hooks/use-shop-author-filter'
import useShopFilter from 'src/hooks/use-shop-filter'
import useTagList from 'src/hooks/use-shop-tag-filter'
import { IStepProgress } from 'src/type'
import { EBlogStatus, IBlog, IBlogUpdate } from 'src/type/shop/blog'
import Advanced from './Advanced'
import MenuTabsSection from './MenuTabs'
const { Option } = Select

interface IProps extends IStepProgress {
  useFormProp: UseFormReturn<IBlogUpdate>
  handleSubmit: (x: any) => void
  onCancel: () => void
  blog?: IBlog
  trigger: UseFormTrigger<IBlogUpdate>
  isDirty: boolean
}

const PostSetting = ({
  useFormProp,
  handleSubmit,
  onCancel,
  blog,
  step,
  setStep,
  trigger,
  isDirty,
}: IProps) => {
  const { control, getValues } = useFormProp
  const [status, setStatus] = useState<string>(getValues('status'))
  const { dataList, getData, setDataList, debounceGetData, handleNextPage } = useShopFilter({
    callback: ShopCategoryAPI.get,
    type: 'blog',
    key: 'blogCategories',
  })
  const { authorList, getAllAuthors, setAuthorList, debounceGetAuthors, handleNextAuthorPage } =
    useAuthorList()

  const { tagList, getAllTags, setTagList, debounceGetTags, handleNextTagPage } = useTagList()

  useEffect(() => {
    if (blog) {
      blog?.shop_blog_author?.name &&
        setAuthorList((prev) => {
          const data = blog.shop_blog_author ? [blog.shop_blog_author] : []
          return {
            ...prev,
            data,
          }
        })
      setTagList((prev) => {
        const data = blog.shop_blog_tag ? [blog.shop_blog_tag] : []
        return {
          ...prev,
          data,
        }
      })
      setDataList((prev) => {
        prev.data = [
          ...blog?.shop_blog_categories?.map((item) => ({ id: item.id, name: item.name })),
        ]
        if (blog.primary_shop_blog_category) {
          prev.data.push(blog.primary_shop_blog_category)
        }
        return prev
      })
    } else {
      debounceGetData()
    }
  }, [blog])

  return (
    <div className='form d-flex flex-column flex-lg-row'>
      <Processbar
        step={step}
        setNewStep={setStep}
        cancelButtonCaption='Blog List'
        onCancel={onCancel}
        onClick={handleSubmit}
        okButtonCaption={BUTTON_TEXT.SAVE}
        okButtonDisabled={!isDirty}
        className='mw-500px'
        canToggle={true}
      />
      <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
        <div className='card card-flush p-10'>
          <SappLabel label='Status' required isLabel />
          <HookFormSelectAntd
            size='large'
            name='status'
            control={control}
            placeholder='Status'
            filterOption={true}
            defaultValue={getValues('status')}
            className='sapp-h-45px fs-6 sapp-field_question-select'
            onChange={(val: unknown) => {
              trigger(['blog_time'])
              setStatus(val as string)
            }}
          >
            {SHOP_BLOG_STATUS.map((status) => (
              <Option key={status.label} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>
          <div className='mt-6'>
            <HookFormDateTime
              control={control}
              name='blog_time'
              placeholder='Choose Time'
              defaultValue={getValues('blog_time')}
              disabled={status === EBlogStatus.DRAFT || status === EBlogStatus.PUBLISH}
            />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-row-fluid'>
        <div className='tab-content'>
          <div className='tab-pane fade show active'>
            <div className='d-flex flex-column gap-7 gap-lg-10'>
              <div className='card card-flush pb-4 pt-10'>
                <div className='card-body pt-0'>
                  <h2 className='mb-10'>Settings</h2>
                  <div className='mb-10 fv-row'>
                    <HookFormSelectAntd
                      name='blog_author_id'
                      placeholder='None'
                      control={control}
                      size='large'
                      showSearch
                      handleNextPage={handleNextAuthorPage}
                      onSearch={async (e) => {
                        debounceGetAuthors(e)
                        return
                      }}
                      label='Author'
                      onFocus={async () => {
                        if (dataList?.data?.length <= 0) {
                          await getAllAuthors()
                          return
                        }
                        debounceGetAuthors()
                      }}
                      onChange={async (e: any) => {
                        if (e === undefined) {
                          debounceGetAuthors()
                        }
                      }}
                      loading={authorList.loading}
                      allowClear
                      classNameHeight='sapp-h-45px'
                      required
                    >
                      {authorList.data?.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        )
                      })}
                    </HookFormSelectAntd>
                  </div>
                  <div className='mb-10 fv-row'>
                    <HookFormSelectMultiple
                      name='blog_category_ids'
                      placeholder='None'
                      control={control}
                      loading={dataList.loading}
                      label='Category'
                      options={dataList.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      handleNextPage={handleNextPage}
                      onSearch={(e: any) => {
                        debounceGetData(e)
                        if (e === undefined) {
                          return
                        }
                      }}
                    />
                  </div>
                  <div className='mb-10 fv-row'>
                    <HookFormSelectAntd
                      required
                      name='primary_blog_category_id'
                      placeholder='None'
                      control={control}
                      size='large'
                      showSearch
                      handleNextPage={handleNextPage}
                      onSearch={async (e) => {
                        debounceGetData(e)
                        return
                      }}
                      label='Primary Category'
                      onFocus={async () => {
                        if (dataList?.data?.length <= 0) {
                          await getData()
                          return
                        }
                      }}
                      onChange={async (e: any) => {
                        debounceGetData(e)
                        if (e === undefined) {
                          debounceGetData()
                        }
                      }}
                      loading={dataList.loading}
                      allowClear
                      classNameHeight='sapp-h-45px'
                    >
                      {dataList.data?.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        )
                      })}
                    </HookFormSelectAntd>
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormTextField
                      control={control}
                      label='URL'
                      name='suffix_url'
                      groupText='sapp.shop.edu.vn/sapp-academy/'
                      className='sapp-h-45px'
                      required
                    />
                  </div>
                  <div className='mb-10 fv-row'>
                    <HookFormSelectAntd
                      name='tag_id'
                      placeholder='None'
                      control={control}
                      size='large'
                      showSearch
                      handleNextPage={handleNextTagPage}
                      onSearch={async (e) => {
                        debounceGetTags(e)
                        return
                      }}
                      label='Tag'
                      onFocus={async () => {
                        if (tagList?.data && tagList?.data?.length <= 0) {
                          await getAllTags()
                          return
                        }
                      }}
                      onChange={async (e: any) => {
                        if (e === undefined) {
                          debounceGetTags()
                        }
                      }}
                      loading={tagList.loading}
                      allowClear
                      classNameHeight='sapp-h-45px'
                    >
                      {tagList.data?.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        )
                      })}
                    </HookFormSelectAntd>
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormTextField
                      name='meta_title'
                      control={control}
                      label='Meta Title'
                      defaultValue={getValues('meta_title')}
                      required
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormEditor
                      height={380}
                      label='Meta Description'
                      name='meta_description'
                      control={control}
                      math={true}
                      className='w-100'
                      defaultValue={getValues('meta_description')}
                      resourceLocation={RESOURCE_LOCATION.MEDIA}
                      object_id={undefined}
                      required
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormTextField
                      name='focus_keyword'
                      control={control}
                      label='Focus Keyword'
                    />
                  </div>

                  <div className='mt-2'>
                    <div className='rounded'>
                      <MenuTabsSection
                        typeofSection={'Advanced'}
                        SectionName={'Advanced'}
                        tabs={[
                          {
                            id: 'tab1',
                            title: 'Advanced',
                            content: <Advanced useForm={useFormProp} />,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostSetting
