export const TITLE = {
  DASHBOARD: 'Dashboard',
  NEWS: 'Tin tức',
  LIST_NEWS: 'Danh sách',
  CREATE_NEWS: 'Tạo tin tức',
  COURSES: 'Courses',
  COURSE_CATEGORY: 'Course category',
  RESOURCE_BANK: 'Kho học liệu',
  QUESTION_BANK: 'Question banks',
  DICTIONARY: 'Dictionary',
  SUPPORT_CENTER: 'Trung tâm hỗ trợ',
  OVERVIEW: 'Báo cáo',
  TICKETS: 'Tickets',
  FAQs: 'FAQs',
  NOTIFICATIONS: 'Notifications',
  SEND_NOTIFICATIONS: 'Gửi thông báo',
  LIST_NOTIFICATIONS: 'Notification List',

  //#region Staffs
  STAFFS: 'Staffs',
  LIST_STAFFS: 'List Staffs',
  CREATE_STAFFS: 'Create Staffs',
  //#endregion

  //#region Sidebar Departments
  DEPARTMENTS: 'Departments',
  DEPARTMENTS_LIST: 'Department List',
  DEPARTMENTS_CREATE: 'Create Department',
  DEPARTMENTS_DETAIL: 'Detail',
  //#endregion

  STUDENTS: 'Students',
  LIST_STUDENTS: 'Danh sách',
  CREATE_STUDENTS: 'Create students',
  TEACHERS: 'Teachers',
  LIST_TEACHERS: 'Danh sách',
  CREATE_TEACHERS: 'Create Teachers',
  EXAM_DETAIL: 'Detail',
  EXAM_LIST: 'Exam List',
}
