import { KTCardBody } from 'src/_metronic/helpers'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { ITable } from 'src/type'
import LoadingTable from './LoadingTable'

const SappBaseTable = ({
  headers,
  children,
  dataResponse,
  loading,
  handlePaginationChange,
  onChange,
  hasCheck,
  isCheckedAll = false,
  data,
  totalItems,
  className,
  showHashtag,
}: ITable) => {
  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        totalItems={totalItems}
        isCheckedAll={isCheckedAll}
        onChange={onChange}
        hasCheck={hasCheck}
        className={className}
        data={data}
        showHashtag={showHashtag}
      >
        {loading ? (
          <>
            {headers?.map((header) => (
              <LoadingTable key={header.label} headers={headers} />
            ))}
          </>
        ) : (
          <>{children}</>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={dataResponse?.metadata?.page_index ?? dataResponse?.meta?.page_index ?? 1}
        pageSize={dataResponse?.metadata?.page_size ?? dataResponse?.meta?.page_size ?? 10}
        totalItems={dataResponse?.metadata?.total_records ?? dataResponse?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default SappBaseTable
