import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Collapse, Row, Space } from 'antd'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { RolesAPI } from 'src/apis/roles'
import HookFormCheckBoxGroup from 'src/components/base/checkbox/HookFormCheckBoxGroup'
import SappModal from 'src/components/base/SappModal'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { z } from 'zod'

interface IProps {
  open: boolean
  setOpen: any
  id?: string | undefined
  roleData: any
  functionCallBack: () => void
}
type Role = {
  code: string
}
const AddRole = ({ open, setOpen, id, roleData, functionCallBack }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [permissionData, setPermissionData] = useState<Array<any>>([])

  const getPermissions = async () => {
    setLoading(true)
    try {
      const res = await RolesAPI.permissionsDetail()
      setPermissionData(Object.entries(res?.data))

      // Set default values for permissions
      if (id && id !== '') {
        const groupedByGroup: any = {}
        if (roleData?.permissions) {
          roleData?.permissions.forEach((item: any) => {
            const group = item.group

            if (!groupedByGroup[group]) {
              groupedByGroup[group] = []
            }

            groupedByGroup[group].push(item?.key)
          })
        }

        Object.entries(res?.data).map((group, index) => {
          const keyGroup = group?.[0]
          if (groupedByGroup?.[keyGroup]?.length > 0 && id) {
            setValue(`groups.${index}`, groupedByGroup?.[keyGroup])
          }
        })
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const createNewRole = async (params?: Object) => {
    setLoading(true)
    try {
      const res = await RolesAPI.addRole(params)
      if (res) {
        toast.success('Saved successfully!')
        functionCallBack()
        handleClose()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const editRole = async (params: {}) => {
    setLoading(true)
    try {
      const roleId = {
        role_id: id,
      }
      Object.assign(params, roleId)
      const res = await RolesAPI.editRole(params)
      if (res) {
        toast.success('Update Successfully!')
        functionCallBack()
        handleClose()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  //validate for input
  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
    code: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Code', 1000)),
    groups: z.array(z.any()),
  })

  const { handleSubmit, control, setValue, reset, watch, unregister } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const { contextHolder } = useConfirm()

  const handleClose = () => {
    setOpen(() => {
      reset()
      setValue('groups', undefined)
      setValue('name', undefined)
      setValue('code', undefined)
      return false
    })
    setPermissionData([])
  }

  useEffect(() => {
    if (open) {
      if (id) {
        setValue('name', roleData?.name || '')
        setValue('code', roleData?.code || '')
      }
      getPermissions()
    }
  }, [open])

  // function submit form
  const onSubmit = (data: any) => {
    const mappedArray = data.groups.flat().filter((element: any) => element !== undefined)
    const paramsUpdate = {
      name: data.name,
      code: data.code,
      description: '',
      permissions: mappedArray,
    }
    if (id) {
      editRole(paramsUpdate)
    } else {
      createNewRole(paramsUpdate)
    }
    if (!roleData?.roles?.some((role: Role) => role?.code === data?.code)) {
      return
    } else {
      handleClose()
    }
  }
  const half_length = Math.ceil(permissionData.length / 2)
  const formData = watch()

  const renderCollapseItems = (dataSlice: any, startingIndex: number) =>
    dataSlice.map((group: any, index: number) => {
      const mappingIndex = index + startingIndex
      const keyGroup = group?.[0]

      return (
        <Collapse
          defaultActiveKey={formData?.groups?.[mappingIndex]?.length > 0 && group[0]}
          expandIconPosition='right'
          key={keyGroup || mappingIndex}
          items={[
            {
              key: group[0],
              label: (
                <div className='d-flex align-items-center form-check form-check-solid '>
                  <input
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue(
                          `groups.${mappingIndex}`,
                          group?.[1].map((item: any) => item.key)
                        )
                      } else {
                        setValue(`groups.${mappingIndex}`, [])
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    className={`form-check-input bg-gray-300 me-2 cursor-pointer`}
                    type='checkbox'
                    checked={formData?.groups?.[mappingIndex]?.length === group?.[1].length}
                  />
                  <label className='fs-6 fw-semibold text-capitalize'>
                    {group?.[0]
                      .replace(/(_management)|_/g, ' ')
                      .replace(/groups/g, 'Nhóm gửi thông báo')}
                  </label>
                </div>
              ),
              children: (
                <HookFormCheckBoxGroup
                  control={control}
                  name={`groups.${mappingIndex}` as const}
                  options={group?.[1]}
                  className='row'
                  classItem='col-12 col-lg-6 mb-7'
                  showToolTip={true}
                />
              ),
            },
          ]}
        />
      )
    })

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        classBody={'p-0 modal-body'}
        dialogClassName={'m-0 modal-dialog-scrollable modal-fullscreen'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <CreateEditLayout
          pageTitle={isEmpty(roleData) ? 'Add Role' : 'Edit Role'}
          onSave={handleSubmit(onSubmit)}
          onCancel={handleClose}
          confirmOnCancel
          maxWidth={1850}
          loading={loading}
        >
          <Space direction='vertical' size={32} style={{ display: 'flex', marginTop: 6 }}>
            <Row gutter={[24, 24]}>
              <Col className='sapp-add-role-label' xs={24} lg={12}>
                <HookFormTextField
                  name='name'
                  control={control}
                  required
                  placeholder='Role name'
                  label='Role name'
                />
              </Col>
              <Col className='sapp-add-role-label' xs={24} lg={12}>
                <HookFormTextField
                  name='code'
                  control={control}
                  required
                  placeholder='Code'
                  label='Code'
                  disabled={!isEmpty(id)}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Space direction='vertical' size={32} style={{ display: 'flex' }}>
                  {renderCollapseItems(permissionData.slice(0, half_length), 0)}
                </Space>
              </Col>
              <Col span={12}>
                <Space direction='vertical' size={32} style={{ display: 'flex' }}>
                  {renderCollapseItems(
                    permissionData.slice(half_length),
                    permissionData.slice(0, half_length).length
                  )}
                </Space>
              </Col>
            </Row>
          </Space>
        </CreateEditLayout>
      </SappModal>
    </>
  )
}
export default AddRole
