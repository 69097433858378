import { useParams } from 'react-router-dom'
import ExamDetail from 'src/components/exam/ExamDetail/ExamDetail'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink, TITLE } from 'src/constants'
import { ExamRouteParams, ITabs } from 'src/type'

const ExamDetailPage = () => {
  const { program } = useParams<ExamRouteParams>()

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.EXAMS}/${program}`,
      title: `${program?.toUpperCase()} Exams`,
    },
    {
      link: '',
      title: TITLE.EXAM_DETAIL,
    },
  ]
  return (
    <PageLayouts pageTitle={`${program} Exam Detail`} breadcrumbs={breadcrumbs}>
      <ExamDetail />
    </PageLayouts>
  )
}

export default ExamDetailPage
