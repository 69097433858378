import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import './CreatedContent.scss'
import { ReactNode } from 'react'
import { useConfirm } from 'src/hooks/use-confirm'
import clsx from 'clsx'

interface IProps {
  name?: string
  actionDelete?: any
  actionEdit?: any
  actionPreview?: any
  children?: ReactNode
  classNameContainer?: string
  containerClick?: any
  iconGrayColor?: any
  disableHoverColor?: boolean
  disableHoverButtonBg?: boolean
  customConfirm?: boolean
  fs?: string | undefined
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void
  docId?: string
  isDraggable?: boolean
}

const CreatedContent = ({
  name,
  actionDelete,
  actionEdit,
  actionPreview,
  children,
  classNameContainer = ' p-4 sapp-mb-24px text-gray-600',
  containerClick,
  iconGrayColor = '800',
  disableHoverButtonBg = false,
  customConfirm = false,
  fs,
  onDragStart,
  onDrop,
  docId,
  isDraggable = false,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const handleDelete = () => {
    if (!customConfirm) {
      actionDelete()
    } else {
      actionDelete()
    }
  }
  return (
    <>
      {contextHolder}

      <div
        id={docId}
        draggable={isDraggable}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={(ev) => ev.preventDefault()}
        onClick={containerClick}
        className={`${clsx({
          'sapp-cursor-move': isDraggable,
        })} card card-body card-hover-color-primary border-gray-300 d-flex justify-content-between flex-row align-items-center sapp-created- ${classNameContainer}`}
      >
        {!children ? <div className='sapp-created-content-name'>{name}</div> : children}
        <div className='d-flex'>
          {actionPreview && (
            <ButtonIconOnly
              iconName='eye'
              onClick={actionPreview}
              iconType='outline'
              bg={'sapp-custom-bg-transparent'}
              iconGrayColor={iconGrayColor}
              disableBackgoundHover={disableHoverButtonBg}
              fs={fs}
            />
          )}
          {actionEdit && (
            <ButtonIconOnly
              iconName='notepad-edit'
              onClick={actionEdit}
              iconType='outline'
              disabled={isDraggable}
              bg={'sapp-custom-bg-transparent'}
              iconGrayColor={iconGrayColor}
              disableBackgoundHover={disableHoverButtonBg}
              fs={fs}
            />
          )}
          {actionDelete && (
            <ButtonIconOnly
              iconName='trash'
              onClick={handleDelete}
              iconType='outline'
              bg={'sapp-custom-bg-transparent'}
              activeColor='danger'
              disabled={isDraggable}
              iconGrayColor={iconGrayColor}
              disableBackgoundHover={disableHoverButtonBg}
              fs={fs}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default CreatedContent
