import { format } from 'date-fns'
import { debounce } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'
import { RolesAPI } from 'src/apis/roles'
import NotData from 'src/components/NotData'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import AddStaff from 'src/components/roles/add-staff/AddStaff'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import UserListGrouping from 'src/components/user-management/UserListGrouping'
import { Keyboard, STAFF_PROFILE } from 'src/constants'
import { CODE_ADMIN, TITLE_ROLE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import useKeyboardShortcut from 'src/hooks/useKeyboardShorcut'
import { Role } from 'src/type'
import { IRoles, IRoleStaff, RoleStaff } from 'src/type/roles'
import { cleanParamsAPI } from 'src/utils'
import { StaffActions } from './StaffActions'

const headers = [
  {
    label: 'Name',
    key: 'name',
    className: 'fs-7 lh-1 fw-bold w-200px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'fs-7 lh-1 fw-bold w-200px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 lh-1 fw-bold w-150px',
  },
  {
    label: 'Joined Date',
    key: 'joined-date',
    className: 'fs-7 lh-1 fw-bold w-100px',
  },
]
interface IProps {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  roleData?: IRoles
}

const ListStaff = ({ setLoading, roleData }: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [user, setUser] = useState<IRoleStaff>()
  const [userSearch, setUserSearch] = useState<RoleStaff[]>()
  const [openBlocked, setOpenBlocked] = useState(false)
  const { id } = useParams()
  const [totalUser, setTotalUser] = useState<number>(0)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const initialValues = {
    text: '',
  }
  const getParams = (text: string) => ({
    text,
  })

  const [searchParams, setSearchParams] = useState<{ text: string }>(initialValues)

  const getListStaff = async (page_index?: number, page_size?: number, params?: Object) => {
    setLoading(true)
    try {
      const response = await RolesAPI.getListStaffs({
        role_id: id as string,
        page_index: page_index || 1,
        page_size: page_size || 10,
        params: params,
      })

      if (response) {
        const getListUser = response?.data?.staffs.map((item) => {
          return item
        })
        setUser(response?.data)
        setUserSearch(getListUser)
        setTotalUser(response?.data?.metadata?.total_records)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteStaff = async (id: string | number, data?: string[]) => {
    setLoading(true)
    try {
      const res = await RolesAPI.deleteStaffs(id, { staff_ids: data })
      if (res) {
        toast.success('Delete Successfully!')
        getListStaff()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getListStaff(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    getListStaff()
  }, [])

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked(userSearch)

  const handleOnClick = () => {
    setOpen(true)
  }

  const blockUser = () => {
    setOpenBlocked(false)
    toggleCheckAll(!listDataChecked)
    deleteStaff(id as string, checkedList)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const debounceSearch = useMemo(() => {
    return debounce(handleSearchChange, 300)
  }, [])

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getListStaff(1, user?.metadata?.page_size || 10, cleanedParams)
  }
  const { profileMe } = useUserContext()
  const allowRenderEditRoles = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.EDIT_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  useEffect(() => {
    handleSearchActive()

    return () => {
      debounceSearch.cancel()
    }
  }, [searchParams])

  // Use the custom hook to focus on the search box when "/" is pressed
  useKeyboardShortcut(Keyboard.SLASH, () => {
    inputRef.current?.focus()
  })

  return (
    <div className='sapp-list-staff col-md-12 col-xl-8'>
      <div className='card'>
        <div className='py-5 px-8'>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${
              totalUser ? ' mb-5' : ''
            }`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={debounceSearch}
                placeholder={'Search member'}
                smallSearch={true}
                className={'sapp-h-40'}
                searchRef={inputRef}
              />
            </div>
            <>
              {checkedList.length > 0 && (
                <div className='sapp-selected-user'>
                  <UserListGrouping
                    okButtonCaption='Yes'
                    cancelButtonCaption='No'
                    body='Bạn có chắc chắn muốn xóa không?'
                    selected={checkedList}
                    blockUser={blockUser}
                    openBlocked={openBlocked}
                    setOpenBlocked={setOpenBlocked}
                    title={'Delete Selected'}
                    className={'sapp-h-40'}
                    disabled={!allowRenderEditRoles}
                  />
                </div>
              )}
              <div className='d-flex justify-content-between'>
                {allowRenderEditRoles && (
                  <ButtonIconPrimary
                    title='Add User'
                    onClick={handleOnClick}
                    iconName='plus'
                    size='small'
                    loading={false}
                  />
                )}
              </div>
            </>
          </div>
          <div className='h-xl-100 mt-3'>
            <div className='table-responsive'>
              <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
                      <SAPPCheckbox
                        checked={isCheckedAll}
                        ktCheck={isCheckedAll}
                        className={'ps-0'}
                        onChange={() => {
                          toggleCheckAll(!isCheckedAll, true)
                        }}
                      />
                      {headers?.map((column) => (
                        <th key={column.label} className={column.className}>
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-semibold'>
                    {(() => {
                      if (!totalUser) {
                        return (
                          <tr>
                            <td colSpan={10}>
                              <NotData />
                            </td>
                          </tr>
                        )
                      }
                      return user?.staffs?.map((item: any, i: number) => {
                        const isChecked = checkedList.includes(item?.id as string)
                        return (
                          <tr className='border-0' key={(item?.id as string) ?? i}>
                            <td>
                              <SAPPCheckbox
                                checkTarget='#kt_table_users .form-check-input'
                                checked={isChecked}
                                ktCheck={isChecked}
                                onChange={() => {
                                  toggleCheck(item?.id!)
                                }}
                              />
                            </td>
                            <td>
                              <Link
                                className='text-gray-800 sapp-text-truncate-1 text-hover-primary'
                                to={`${STAFF_PROFILE}/${item?.id}/overview`}
                              >
                                {item?.detail?.full_name}
                              </Link>
                            </td>
                            <td className='w-50px'>
                              <span className='sapp-text-truncate-1 text-wrap'>
                                {item?.detail?.email}
                              </span>
                            </td>
                            <td>{item?.detail?.phone}</td>
                            <td>
                              <span>
                                {(() => {
                                  if (item?.created_at === null) {
                                    return ''
                                  }
                                  const date = new Date(item?.created_at)
                                  return `${format(date, 'dd/MM/yyyy')}`
                                })() ?? '-'}
                              </span>
                            </td>
                            <td className='text-end'>
                              <StaffActions id={item?.id} staffId={id} deleteStaff={deleteStaff} />
                            </td>
                          </tr>
                        )
                      })
                    })()}
                  </tbody>
                </table>
              </div>
            </div>
            <PagiantionSAPP
              currentPage={user?.metadata?.page_index || 1}
              pageSize={user?.metadata?.page_size || 10}
              totalItems={totalUser}
              handlePaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>
      <AddStaff
        open={open}
        setOpen={setOpen}
        id={id as string}
        functionCallBack={getListStaff}
        roleData={roleData}
      />
    </div>
  )
}

export default ListStaff
