import { Tooltip } from 'antd'
import { ReactNode, useEffect } from 'react'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import './ActionCell.scss'
type Props = {
  children: ReactNode
  customWidth?: string
  className?: string
  height?: number
}

const ActionCell = ({ children, customWidth, height }: Props) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <>
      <div data-kt-menu-trigger='click' data-kt-menu-placement='left-start'>
        <Tooltip title='Action' color='#ffffff' placement='top'>
          <span
            className={`btn btn-icon btn-icon-gray-500 w-35px w-lg-40px bg-none sapp-btn-action-cell`}
            style={{ height: height }}
          >
            <svg
              className='sapp-btn-action-cell_svg'
              xmlns='http://www.w3.org/2000/svg'
              height='1.05em'
              viewBox='0 0 128 512'
            >
              <path d='M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z' />
            </svg>
          </span>
        </Tooltip>
      </div>
      {/* </OverlayTrigger> */}
      <div
        className={`${
          customWidth ?? 'w-200px'
        } menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 py-3 sapp-menu-shadow`}
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {children}
        {/* end::Menu item */}
      </div>
    </>
  )
}

export default ActionCell
