import { VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'

export const schema = z.object({
  name: z.string({ message: VALIDATION_FIELD }),
  short_name: z.string().optional().nullable(),
  type: z.string({ message: VALIDATION_FIELD }),
  parent_id: z.string({ message: VALIDATION_FIELD }),
  unit: z.string().array().nonempty({ message: VALIDATION_FIELD }).max(2),
  description: z.string().optional(),
})
