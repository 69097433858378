const SearchIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'>
      <path
        fill='#99A1B7'
        d='m16.917 15.183-2.642-2.5a7.714 7.714 0 0 1-1.783 1.667l2.716 2.583a1.24 1.24 0 1 0 1.667-1.816l.042.066Z'
        opacity='.3'
      />
      <path
        fill='#99A1B7'
        d='M8.283.667a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm0 12.8a5.292 5.292 0 1 1 4.894-3.271 5.284 5.284 0 0 1-4.894 3.27Z'
      />
    </svg>
  )
}

export default SearchIcon
