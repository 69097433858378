import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { DepartmentAPI } from 'src/apis/department'
import { DEPARTMENT_TYPES } from 'src/type/department/enum'

const useDepartmentParents = (type: DEPARTMENT_TYPES) => {
  const fetchDepartmentParents = async (
    page_index: number,
    page_size: number,
    type: DEPARTMENT_TYPES
  ) => {
    const res = await DepartmentAPI.getParent(page_index, page_size, type)
    return res
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery({
      queryKey: ['departmentParents'], // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchDepartmentParents(pageParam, 10, type) // Fetch with pageParam and a fixed page size
      },
      getNextPageParam: (lastPage: any) => {
        return lastPage?.data.metadata.page_index < lastPage?.data.metadata.total_pages
          ? lastPage?.data.metadata.page_index + 1
          : undefined
      },
      enabled: type && type !== DEPARTMENT_TYPES.BOARD,
      refetchOnWindowFocus: false,
    })

  return {
    parents: data?.pages.flatMap((page) => page.data.data) ?? [], // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
  }
}

export default useDepartmentParents
