import { IQuestion } from 'src/components/question-bank/shared/interfaces'
import { CourseSection } from './courses'
import { Abstract } from './abstract'
import { IMeta } from '.'

export class Quiz extends Abstract {
  name = ''
  description = ''
  quiz_question_mode = ''
  status = ''
  number_of_multiple_choice_questions = 0
  number_of_essay_questions = 0
  is_published = true
  is_public = true
  password = ''
  is_graded = true
  grading_method = ''
  multiple_choice_multiplier = 0
  essay_multiplier = 0
  required_percent_score = 0
  minimum_score = 0
  is_limited = true
  limit_count = 0
  is_tutor = true
  quiz_timed = ''
  setting = ''
  quiz_question_type = ''
  quiz_type = ''
  grading_preference = ''
  created_by = ''
  created_from = ''
  published_at = new Date()
  started_at = new Date()
  finished_at = new Date()
  course_category_id = ''
  subject = ''
  course_category = ''
  sections: any[] = []
  course_section = new CourseSection()
  constructed_questions: IQuestion[] = []
  multiple_choice_questions: IQuestion[] = []
  state: QuizState = new QuizState()

  constructor(entity: Partial<Quiz>) {
    super(entity)
    Object.assign(this, entity)
  }
}

export class QuizState {
  constructor() {
    this.condition = new QuizCondition()
    this.student_status = []
  }

  student_status: Array<string>
  condition: QuizCondition
}

export class QuizCondition {
  constructor() {
    this.can_edit_quiz = true
    this.can_delete_quiz = true
  }

  can_edit_quiz: boolean
  can_delete_quiz: boolean
}

export interface IClassQuiz {
  class_id: string
  id: string
  quiz_id: string
  quiz: {
    id: string
    name: string
    quiz_type: string
    quiz_question_type: 'MULTIPLE_CHOICE' | 'ESSAY' | 'MIX'
  }
}

export interface IClassUserQuizzes {
  class_user_id: string
  end_time: string
  id: string
  quiz_id: string
  staff: {
    detail: {
      full_name: string
    }
    id: string
  }
  staff_id: string
  start_time: string
  attempt: {
    id: string
    created_at: string
    updated_at: string
    deleted_at: Date
    user_id: string
    quiz_id: string
    description: string
    total_attempt_time: string
    is_graded: false
    score: number
    major_score: number
    english_score: string
    ratio_score: string
    multiple_choice_score: number
    constructed_score: number
    feed_back: string
    status: string
    feedback_user_id: string
    quiz_position_mapping: number
    class_user_id: string
    number_of_attempts: number
    started_at: Date
    finished_at: Date
    is_publish_detail: boolean
    start_time: Date
    attempt_gradings?: Array<any>
    grading_status?: string
  }
  user: {
    detail: {
      full_name: string
    }
    hubspot_contact_id: string
    id: string
    user_contacts: Array<{ email: string; id: string; is_default: boolean }>
  }
  class_user: {
    id: string
  }
  quiz: {
    id: string
  }
}
export interface IQuizDetail {
  class_quiz: IClassQuiz
  class_user_quizzes: IClassUserQuizzes[]
  metadata: IMeta
}

//#region Quiz Result Answers
export interface IQuizResultAnswersRes {
  success: boolean
  data: IQuizResultAnswersData
}

export interface IQuizResultAnswersData {
  metadata: Metadata
  answers: Answer[]
}

export interface Metadata {
  total_pages: number
  total_records: number
  page_index: number
  page_size: number
}

export interface Answer {
  id: string
  quiz_attempt_id: string
  question_id: string
  answer_file: any
  is_correct: boolean
  time_spent: number
  active: any
  topic_attempt_id: any
  requirement_id: any
  question: Question
}

export interface Question {
  id: string
  question_filter_id: string
  question_content: string
  level: string
  qType: string
  question_topic: QuestionTopic
  question_filter: QuestionFilter
  question_report: QuestionReport
  requirements: any[]
  quiz_question_instances: QuizQuestionInstance[]
}

export interface QuestionTopic {
  id: string
  name: string
}

export interface QuestionFilter {
  id: string
  part: Part
  chapter: Chapter
}

export interface Part {
  id: string
  name: string
  short_name: any
}

export interface Chapter {
  id: string
  name: string
  short_name: any
}

export interface QuestionReport {
  ratio: number
  average_time: number
}

export interface QuizQuestionInstance {
  id: string
  quiz_id: string
  section: Section
}

export interface Section {
  id: string
  name: string
}
