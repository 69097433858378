import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { ClassesApi } from 'src/apis/classes'
import { useParams } from 'react-router-dom'
import { cleanParamsAPI } from 'src/utils'
import SappTable from 'src/components/base/SappTable'
import LoadingTable from 'src/common/LoadingTable'
import { IMentor, IMentorClass, IMentorClassList } from 'src/type/mentors'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import ActionCell from 'src/components/base/action/ActionCell'
import AddMentor from '../../add-mentor/AddMentor'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonDanger from 'src/components/ui/button-danger/ButtonDanger'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const headers = [
  {
    label: 'Mentor Name',
    key: 'mentor',
    className: 'fs-7 fw-bold min-w-100px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 fw-bold min-w-100px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'min-w-100px',
  },
  {
    label: 'Main Reponsible',
    key: 'reposible',
    className: 'min-w-100px text-center',
  },
]

interface IProps {
  loading: boolean
  classDetail: any | undefined
}

const ListMentorClass = ({ loading, classDetail }: IProps) => {
  const [mentorList, setMentorList] = useState<IMentorClassList>()
  const [mentorData, setMentorData] = useState<IMentor[]>()
  const [open, setOpen] = useState<boolean>(false)
  const { contextHolder, confirm } = useConfirm()
  const { id } = useParams()
  const [loadingMentor, setLoadingMentor] = useState(false)
  const [isEnded, setIsEnded] = useState<boolean>(false)

  const getParams = (text: string) => ({
    text,
  })

  const initialValues = {
    text: '',
  }
  const [searchParams, setSearchParams] = useState<{ text: string }>(initialValues)

  const getClassMentors = async (page_index?: number, page_size?: number, params?: Object) => {
    setTimeout(async () => {
      try {
        const response = await ClassesApi.getClassMentor({
          class_id: id,
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: params,
        })
        if (response) {
          setMentorList(response?.data)
          setMentorData(response?.data?.data.map((item: IMentorClass) => ({ ...item.mentor })))
        }
      } catch (error) {
        // do nothing
      }
    })
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassMentors(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    setLoadingMentor(true)
    getClassMentors()
      .catch((error) => {
        // console.error('API call failed:', error)
      })
      .finally(() => {
        setLoadingMentor(false)
      })
    setIsEnded(classDetail?.status === 'ENDED' ? true : false)
  }, [])

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<IMentor>(mentorData)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassMentors(1, mentorList?.metadata?.page_size || 10, cleanedParams)
  }

  const handleDeleteMentor = (mentorId: string[]) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        const data = mentorList?.data.filter(
          (item: IMentorClass) => !mentorId.includes(item.mentor.id)
        )
        const isNotMain = data?.find((item: IMentorClass) => item.is_main_mentor === true)
        const mentors = data?.map((item, idx) => ({
          mentor_id: item.mentor.id,
          is_main_mentor: isNotMain?.id ? item.is_main_mentor : idx === 0,
        }))
        await ClassesApi.updateClass({ mentors, id: classDetail.id })
        toggleCheckAll(false)
        getClassMentors()
      },
    })
  }

  const handleMainMentor = (mentorId: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có muốn thay đổi Mentor chình không?'],
      onClick: async () => {
        try {
          setLoadingMentor(true)
          const mentors = mentorList?.data.map((item: IMentorClass) => {
            return {
              mentor_id: item.mentor.id,
              is_main_mentor: mentorId === item.id,
            }
          })
          await ClassesApi.updateClass({ mentors, id: classDetail.id })
          getClassMentors()
        } catch {
          // donothing
        } finally {
          setLoadingMentor(false)
        }
      },
    })
  }

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10 flex sapp-min-h-100vh'>
        <div className='py-8 px-10 '>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${
              mentorList?.data.length ? ' mb-5' : ''
            }`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search mentor'}
                smallSearch={true}
                onSubmit={handleSearchActive}
                isListScreen
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearchActive()
                }}
                className='ms-4'
                loading={loading}
                size='small'
              />
            </div>
            <div className='d-flex justify-content-between'>
              {listDataChecked?.length > 0 ? (
                <>
                  <div className='fw-bold sapp-checkbox-text-custom me-5 pt-5'>
                    <span>{listDataChecked?.length}</span> Selected
                  </div>
                  <ButtonDanger
                    title={'Delete Selected'}
                    onClick={() => handleDeleteMentor(checkedList)}
                    loading={loading}
                    size='small'
                  />
                </>
              ) : (
                <ButtonIconPrimary
                  iconName='plus'
                  title='Add Mentors'
                  onClick={() => setOpen(true)}
                  size='small'
                  loading={false}
                />
              )}
            </div>
          </div>
          <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
            <div className='container'>
              <div className='row px-4 py-8 sapp-overflow-y-layout'>
                <SappTable
                  headers={headers}
                  loading={loadingMentor}
                  data={mentorData}
                  isCheckedAll={isCheckedAll}
                  disabled={isEnded}
                  onChange={() => {
                    toggleCheckAll(!isCheckedAll, true)
                  }}
                >
                  {loading ? (
                    <>
                      {headers.map((header, i) => (
                        <LoadingTable key={header.label} headers={headers} />
                      ))}
                    </>
                  ) : (
                    <>
                      {mentorList?.data?.map((data: IMentorClass, i: number) => {
                        const isChecked = checkedList.includes(data?.mentor?.id)
                        return (
                          <tr className='border-0' key={data?.mentor?.id ?? i}>
                            <td className='text-start'>
                              <SAPPCheckbox
                                checkTarget='#kt_table_users .form-check-input'
                                checked={isChecked}
                                onChange={() => {
                                  toggleCheck(data?.mentor?.id!)
                                }}
                                disabled={isEnded}
                              />
                            </td>
                            <td className='fs-6 lh-1 fw-semibold sapp-item-column text-start'>
                              {data.mentor?.detail?.full_name}
                            </td>
                            <td className='text-start'>{data.mentor?.detail?.phone}</td>
                            <td className='text-start'>{data.mentor?.detail?.email}</td>
                            <td>
                              <div className='d-flex justify-content-center'>
                                <SAPPRadio
                                  checked={data.is_main_mentor}
                                  ktCheck={data.is_main_mentor}
                                  onChange={() => {
                                    handleMainMentor(data.id)
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <ActionCell customWidth='w-150px'>
                                <div
                                  className='menu-item px-3'
                                  onClick={() => handleDeleteMentor([data?.mentor?.id])}
                                >
                                  <div className='menu-link px-3'>Delete</div>
                                </div>
                              </ActionCell>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                </SappTable>
                <PagiantionSAPP
                  currentPage={mentorList?.metadata?.page_index || 1}
                  pageSize={mentorList?.metadata?.page_size || 10}
                  totalItems={mentorList?.metadata?.total_records}
                  handlePaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
          <AddMentor
            open={open}
            id={classDetail.id}
            setOpen={setOpen}
            getClassMentor={getClassMentors}
          />
        </div>
      </div>
    </>
  )
}

export default ListMentorClass
