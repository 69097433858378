import React from 'react'
import toast from 'react-hot-toast'

import ModalImport from 'src/components/base/upload-file/ModalImport'
import { EXAM_PROGRAM } from 'src/type'

interface IModalImportStaffProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  program: EXAM_PROGRAM
}

const ExamUploadModal = ({ open, setOpen, program }: IModalImportStaffProps) => {
  const beforeUpload = (file: File) => {
    const isExcel =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    const isCSV = file.type === 'text/csv'
    if (!isExcel && !isCSV) {
      toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
      return false
    }
    if (file.size > 5 * 1024 * 1024) {
      toast.error('The file is too large! It must be less than or equal to 5 MB!')
      return false
    }
    return true
  }
  const fileTemplateName = (() => {
    switch (program) {
      case EXAM_PROGRAM.ACCA:
        return {
          fileName: 'ACCA_EXAMINATION_TEMPLATE.xlsx',
          url: '/ACCA_EXAMINATION_TEMPLATE.xlsx',
        }
      case EXAM_PROGRAM.CFA:
        return {
          fileName: 'CFA_EXAMINATION_TEMPLATE.xlsx',
          url: '/CFA_EXAMINATION_TEMPLATE.xlsx',
        }
      case EXAM_PROGRAM.CMA:
        return {
          fileName: 'CMA_EXAMINATION_TEMPLATE.xlsx',
          url: '/CMA_EXAMINATION_TEMPLATE.xlsx',
        }
      default:
        return {
          fileName: 'CFA_EXAMINATION_TEMPLATE.xlsx',
          url: '/CFA_EXAMINATION_TEMPLATE.xlsx',
        }
    }
  })()
  return (
    <div>
      <ModalImport
        open={open}
        setOpen={setOpen}
        title='Import class'
        templateFile={fileTemplateName.url}
        templateFileName={fileTemplateName.fileName}
        uploadUrl={`examination/import`}
        beforeUpload={(file) => beforeUpload(file)}
        type='exam'
        program={program}
      />
    </div>
  )
}
export default ExamUploadModal
