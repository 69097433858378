import DepartmentList from 'src/components/departments/DepartmentList'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const DepartmentsPage = () => {
  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: ``,
      title: 'Departments',
    },
  ]

  return (
    <PageLayouts pageTitle={`Departments`} breadcrumbs={breadcrumbs}>
      <DepartmentList />
    </PageLayouts>
  )
}

export default DepartmentsPage
