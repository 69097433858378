import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Row, Select } from 'antd'
import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { PageLink } from 'src/constants'
import useDepartmentParents from 'src/hooks/useDepartmentParent'
import { IDepartmentForm } from 'src/type'
import { BUSINESS_UNITS, DEPARTMENT_TYPES } from 'src/type/department/enum'
import { schema } from './schema'
import styles from './styles.module.scss'

interface IProps {
  data?: IDepartmentForm
  drawerOpen?: boolean
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>
  submit: (...args: any[]) => any
}

const MAX_COUNT = 2
const DepartmentForm = ({ data, submit, drawerOpen, setDrawerOpen }: IProps) => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [type, setType] = useState<DEPARTMENT_TYPES | undefined>(data?.type || undefined)

  const { parents, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage, refetch } =
    useDepartmentParents(type || DEPARTMENT_TYPES.BOARD)

  const { control, handleSubmit, reset, setValue } = useForm<IDepartmentForm>({
    resolver: zodResolver(schema),
    defaultValues: data ? data : { unit: [BUSINESS_UNITS.HO] },
  })

  const [units, setUnits] = useState<{ name: string; value: string }[]>([
    {
      name: BUSINESS_UNITS.HO,
      value: BUSINESS_UNITS.HO,
    },
  ])

  const departments = Object.values(DEPARTMENT_TYPES).map((type) => ({
    name: capitalize(type.replaceAll('_', ' ')),
    value: type,
  }))

  const onSubmitHandler: SubmitHandler<IDepartmentForm> = (formData) => {
    const formattedData = {
      ...formData,
      unit: formData?.unit?.filter((item) => item !== BUSINESS_UNITS.HO)[0],
    }
    submit(formattedData)
  }

  useEffect(() => {
    setValue('unit', [BUSINESS_UNITS.HO])
    if (type === DEPARTMENT_TYPES.DEPARTMENT) {
      setUnits(
        Object.values(BUSINESS_UNITS)
          // .filter((unit) => unit !== BUSINESS_UNITS.HO)
          .map((unit) => ({
            name: unit,
            value: unit,
          }))
      )
    } else {
      setUnits([
        {
          name: BUSINESS_UNITS.HO,
          value: BUSINESS_UNITS.HO,
        },
      ])
    }
  }, [type])

  useEffect(() => {
    refetch()
  }, [type])

  useEffect(() => {
    reset()
  }, [drawerOpen])

  return (
    <>
      <div className={clsx(['row gy-10'])}>
        <HookFormTextField control={control} name='name' label='Department Name' required />
        <HookFormTextField control={control} name='short_name' label='Department Short Name' />
        <Row className='w-100' gutter={[24, 24]}>
          <Col sm={24} xl={8}>
            <HookFormSelectAntd
              control={control}
              name='type'
              label='Department Level'
              required
              onChange={(val) => setType(val as DEPARTMENT_TYPES)}
            >
              {departments.map((department) => {
                return (
                  <Select.Option key={department.value} value={department.value}>
                    {department.name}
                  </Select.Option>
                )
              })}
            </HookFormSelectAntd>
          </Col>
          <Col sm={24} xl={8}>
            <HookFormSelectAntd
              control={control}
              name='parent_id'
              label='Department Parent'
              required
              loading={isLoading || isFetchingNextPage}
              disabled={type === DEPARTMENT_TYPES.BOARD}
              handleNextPage={() => hasNextPage && fetchNextPage()}
            >
              {parents.map((parent) => {
                return (
                  <Select.Option key={parent.id} value={parent.id}>
                    {parent.name}
                  </Select.Option>
                )
              })}
            </HookFormSelectAntd>
          </Col>
          <Col sm={24} xl={8}>
            <HookFormSelectMultiple
              control={control}
              name='unit'
              label='Unit'
              required
              loading={false}
              maxCount={MAX_COUNT}
              onSearch={() => {}}
              options={[
                ...units.map((unit) => {
                  return {
                    label: unit.name,
                    value: unit.value,
                    disabled: unit.value === BUSINESS_UNITS.HO,
                  }
                }),
              ]}
            />
          </Col>
        </Row>
        <div>
          <HookFormEditor
            height={350}
            name='description'
            control={control}
            resourceLocation={RESOURCE_LOCATION.DEPARTMENT}
            label='Description'
            object_id={undefined}
            defaultValue={data?.description}
          />
        </div>
      </div>
      <div className={clsx({ [styles.createDrawerFooter]: !id }, styles.formFooter)}>
        <SAPPDialogButtonsCancelSubmit
          cancelClick={() => {
            if (id) {
              navigate(`${PageLink.DEPARTMENTS}/${id}/OVERVIEW`)
            } else {
              reset()
              setDrawerOpen!(false)
            }
          }}
          cancelButtonCaption={'Cancel'}
          okButtonCaption={'Submit'}
          okOnClick={handleSubmit(onSubmitHandler)}
          className='justify-content-end d-flex m-0'
        />
      </div>
    </>
  )
}

export default DepartmentForm
