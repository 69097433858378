import { useLocation, useParams } from 'react-router-dom'
import { DETAIL_TAB_TITLE } from 'src/constants'
import ExamOverview from './ExamOverview'
import ExamSetting from './ExamSetting'

const ExamTabs = () => {
  const location = useLocation()
  const { id } = useParams()

  switch (location.pathname.split(`/${id}/`)[1]) {
    case DETAIL_TAB_TITLE.OVERVIEW:
      return <ExamOverview />
    case DETAIL_TAB_TITLE.SETTING:
      return <ExamSetting />

    default:
      return <ExamOverview />
  }
}

export default ExamTabs
