import { PageLink } from 'src/constants'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_STORY_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { useNavigate } from 'react-router-dom'

interface IProps {
  searchTerm: string | null
  sortSortBy: string | null
  fromDate: Date | null
  toDate: Date | null
}

const StoryListToolbar = ({ searchTerm, sortSortBy, fromDate, toDate }: IProps) => {
  const { profileMe } = useUserContext()
  const allowRenderCreateStory = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STORY_GR.CREATE_STORY) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const navigate = useNavigate()
  return (
    <div className='sapp-height-list--grouping text-end row justify-content-end'>
      {allowRenderCreateStory && (
        <ButtonIconPrimary
          iconName='plus'
          title='Create'
          onClick={() => navigate(PageLink.CREATE_STORY)}
          size='small'
          loading={false}
        />
      )}
    </div>
  )
}

export { StoryListToolbar }
