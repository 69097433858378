import { Spin } from 'antd'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import ItemProfile from 'src/components/base/ItemProfile'
import { PageLink } from 'src/constants'
import { useDepartment } from 'src/context/DepartmentContext'
import styles from './styles.module.scss'

const DepartmentOverview = () => {
  const { data, isLoading, id } = useDepartment()

  return (
    <>
      <div className='card-header'>
        <div className='card-title m-0 d-flex justify-content-between w-100'>
          <h3 className='fw-bold m-0'>Overview</h3>
          {/* {allowRenderEdit && ( */}
          <Link
            to={`${PageLink.DEPARTMENTS}/${id}/setting`}
            className='btn btn-sm btn-primary align-self-center px-5 py-3'
          >
            Edit Department
          </Link>
          {/* )} */}
        </div>
      </div>
      <Spin spinning={isLoading} className={styles.spin}>
        <div className='card-body p-9'>
          <ItemProfile
            title={'Name'}
            body={`${
              data?.name && data?.short_name
                ? `${data.name} (${data.short_name})`
                : data?.name || data?.short_name || '-'
            }`}
          />
          <ItemProfile title={'Short Name'} body={`${data?.short_name || '-'}`} />
          <ItemProfile
            title={'Unit'}
            body={<span className={`badge badge-primary`}>{data?.unit || '-'}</span>}
          />
          <ItemProfile title={'Department Level'} body={`${data?.type || '-'}`} />
          <ItemProfile title={'Department Parent'} body={`${data?.parent?.unit || '-'}`} />
          <ItemProfile title={'Number of Employees'} body={`${data?.staff_count || '-'}`} />
          <ItemProfile
            title={'Created date'}
            body={`${dayjs(data?.created_at).format('DD/MM/YYYY') || '-'}`}
          />
        </div>
      </Spin>
    </>
  )
}

export default DepartmentOverview
