import { Card, Col, ConfigProvider, Input, InputRef, Row } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { RolesAPI } from 'src/apis/roles'
import Download from 'src/common/CustomIcons/Download'
import SearchIcon from 'src/common/CustomIcons/SearchIcon'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import PageLayouts from 'src/components/layout/PageLayouts'
import AddRole from 'src/components/roles/add-role/AddRole'
import AddStaff from 'src/components/roles/add-staff/AddStaff'
import RoleInfo from 'src/components/roles/list/RoleInfo'
import { Keyboard, PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_ROLE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { UserExportHelper } from 'src/helper/export'
import { ITabs, Role } from 'src/type'
import { IRoles, IRolesList } from 'src/type/roles'
import styles from './RolesList.module.scss'
import './RolesList.scss'
import { debounce } from 'lodash'
import useKeyboardShortcut from 'src/hooks/useKeyboardShorcut'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.ROLES}`,
    title: 'Roles List',
  },
]

const RolesList = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const [rolesList, setRolesList] = useState<IRolesList>()
  const [openModelStaff, setOpenModelStaff] = useState<boolean>(false)
  const [roleId, setRoleId] = useState<string>('')
  const [roleData, setRoleData] = useState<IRoles>()
  const [openModelRole, setOpenModelRole] = useState<boolean>(false)
  const { profileMe } = useUserContext()
  const allowRenderCreate = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.CREATE_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const inputRef = useRef<InputRef | null>(null)

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const fetchRoutesList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await RolesAPI.getListRoles({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      if (res) {
        setRolesList(res?.data)
      }
    } catch (error) {}
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchRoutesList(page_index, page_size)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  const handleOpenModelStaffs = (id: string, role?: IRoles) => {
    setRoleId(id)
    setOpenModelStaff(true)
    setRoleData(role)
  }

  const handleOpenEditRole = (id: string, role: IRoles) => {
    setRoleId(id)
    setRoleData(role)
    setOpenModelRole(true)
  }

  const handleAddRole = () => {
    setRoleId('')
    setRoleData(undefined)
    setOpenModelRole(true)
  }

  const onClickExportPermission = async () => {
    setLoadingExport(true)
    await UserExportHelper.exportDataPermission()
    setLoadingExport(false)
  }
  const [loading, setLoadingExport] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const debounceSearch = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  useEffect(() => {
    fetchRoutesList(1, 10, {
      name: searchTerm,
    })

    return () => {
      debounceSearch.cancel()
    }
  }, [searchTerm])

  // Use the custom hook to focus on the search box when "/" is pressed
  useKeyboardShortcut(Keyboard.SLASH, () => {
    inputRef.current?.focus()
  })

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            hoverBorderColor: '#ffb800',
            activeBorderColor: '#ffb800',
            activeShadow: '',
            borderRadius: 6,
            colorBorder: '#f9f9f9',
          },
        },
      }}
    >
      <PageLayouts pageTitle='Roles List' breadcrumbs={breadcrumbs}>
        <Card className='mb-5'>
          <div className={styles.cardContainer}>
            <Input
              size='large'
              placeholder='Search role'
              prefix={<SearchIcon />}
              style={{ width: '30%', backgroundColor: '#f9f9f9' }}
              onChange={debounceSearch}
              allowClear
              autoFocus
              ref={inputRef}
            />
            <Row gutter={10}>
              <Col>
                {allowRenderCreate && (
                  <ButtonIcon title={'New Role'} onClick={() => handleAddRole()} isListScreen>
                    <KTIcon iconName='plus' className='fs-2 position-relative sapp-top-px' />
                  </ButtonIcon>
                )}
              </Col>
              <Col>
                <ButtonIcon
                  title={'Export'}
                  loading={loading}
                  disabled={!allowRenderCreate}
                  onClick={onClickExportPermission}
                  customButton='btn btn-light d-flex align-items-center btn-active-primary sapp-font-import-student sapp-role-btn-icon fw-semibold'
                  isListScreen
                >
                  <Download className={styles.downloadIcon} />
                </ButtonIcon>
              </Col>
            </Row>
          </div>
        </Card>

        <div className='row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-5 g-xl-9'>
          {rolesList?.roles?.map((role: IRoles) => (
            <RoleInfo
              key={role?.id}
              role={role}
              handleOpenModelStaffs={handleOpenModelStaffs}
              handleOpenModelRole={handleOpenEditRole}
            />
          ))}
        </div>
        <PagiantionSAPP
          currentPage={rolesList?.metadata?.page_index || 1}
          pageSize={rolesList?.metadata?.page_size || 10}
          totalItems={rolesList?.metadata?.total_records}
          handlePaginationChange={handlePaginationChange}
        />
        <AddStaff
          open={openModelStaff}
          setOpen={setOpenModelStaff}
          id={roleId as string}
          roleData={roleData}
          functionCallBack={() => {
            fetchRoutesList(
              rolesList?.metadata?.page_index || 1,
              rolesList?.metadata?.page_size || 10
            )
          }}
        />
        <AddRole
          open={openModelRole}
          setOpen={setOpenModelRole}
          id={roleId as string}
          roleData={roleData}
          functionCallBack={() => {
            fetchRoutesList(
              rolesList?.metadata?.page_index || 1,
              rolesList?.metadata?.page_size || 10
            )
          }}
        />
      </PageLayouts>
    </ConfigProvider>
  )
}

export default withAuthComponents(RolesList)
